import { Functions, httpsCallable } from 'firebase/functions'
import {
  AddSurveyOwnerInput,
  AddSurveyOwnerOutput,
  ArchiveSubmissionsInput,
  ArchiveSubmissionsOutput,
  CreateSurveyDocsInput,
  CreateSurveyDocsOutput,
  GetResultsInput,
  GetResultsOutput,
  RegisterCompletionInput,
  RegisterCompletionOutput,
  SubscribeInput,
  SubscribeOutput,
} from '@shared-types/functions/Interfaces'

export function getResults(functionsInstance: Functions) {
  return httpsCallable<GetResultsInput, GetResultsOutput>(functionsInstance, 'getResults')
}
export function createSurveyDocs(functionsInstance: Functions) {
  return httpsCallable<CreateSurveyDocsInput, CreateSurveyDocsOutput>(
    functionsInstance,
    'createSurveyDocs',
  )
}

export function addSurveyOwner(functionsInstance: Functions) {
  return httpsCallable<AddSurveyOwnerInput, AddSurveyOwnerOutput>(
    functionsInstance,
    'addSurveyOwner',
  )
}

export function registerCompletion(functionsInstance: Functions) {
  return httpsCallable<RegisterCompletionInput, RegisterCompletionOutput>(
    functionsInstance,
    'registerCompletion',
  )
}

export function subscribe(functionsInstance: Functions) {
  return httpsCallable<SubscribeInput, SubscribeOutput>(functionsInstance, 'subscribe')
}

export function archiveSubmissions(functionsInstance: Functions) {
  return httpsCallable<ArchiveSubmissionsInput, ArchiveSubmissionsOutput>(
    functionsInstance,
    'archiveSubmissions',
  )
}
