import { Survey } from '@shared-types/surveys/Interfaces'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom'
import OutdatedBrowser from './components/ui/OutdatedBrowser'
import { ServiceContext } from './modules/services/context'
import { StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'

import { services } from './services'

// Dynamic Imports
const Home = lazy(() => import('./components/website/index'))
const AdminRouter = lazy(() => import('./components/dashboard/AdminRouter'))
const SurveyWrapper = lazy(() => import('./components/app/SurveyWrapper'))
const NotFound = lazy(() => import('./components/ui/NotFound'))

const Router: React.FC = () => {
  useEffect(() => {
    services.userService.initialise()
    services.analyticsService.initialise()
    services.loggingService.initialise(services.crashReportingService)
    services.crashReportingService.initialise()
    return () => {
      services.userService.dispose()
      services.analyticsService.dispose()
      services.loggingService.dispose()
      services.crashReportingService.dispose()
    }
  }, [])
  return (
    /* fallback prop accepts any react element while waiting for component to load */
    <Suspense fallback={<p>Loading...</p>}>
      <ServiceContext.Provider value={services}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
          <BrowserRouter>
            <Routes>
              <Route path="survey/:surveyName" element={<SurveyFinder />} />
              <Route path="admin/*" element={<AdminRouter />} />
              <Route path="/" element={<Home />} />

              <Route path="/*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </StyleSheetManager>
      </ServiceContext.Provider>
    </Suspense>
  )
}

const SurveyFinder = () => {
  const { surveyName } = useParams()
  const [survey, setSurvey] = useState<Survey | 'loading' | 'not-found'>('loading')
  useEffect(() => {
    if (!surveyName) return

    getSurvey(surveyName).then(
      (currentSurvey) => {
        services.loggingService.debug('survey', currentSurvey)
        setSurvey(currentSurvey)
      },
      (e) => {
        const error = e as Error
        services.loggingService.error('Error getting survey', { error })
        setSurvey('not-found')
      },
    )
  }, [surveyName])
  if (window.MSCompatibleInfo != null) {
    console.log('internet explorer')
    return <OutdatedBrowser />
  }
  if (!surveyName) return <NotFound />
  if (survey === 'loading') return <p>Loading...</p>
  if (survey === 'not-found') return <NotFound />
  return <SurveyWrapper questions={survey.questions} settings={survey.settings} />
}

async function getSurvey(surveyName: string): Promise<Survey> {
  try {
    const survey = (await import(`./surveys/${surveyName}.tsx`)).default as Survey
    return survey
  } catch (e) {
    throw new Error(`Survey ${surveyName} not found`)
  }
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

export default Router
