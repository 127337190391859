import { MessagerService } from './MessagerService'

export class MockMessagerService extends MessagerService {
  public info(message: string): void {}
  public success(message: string): void {}
  public warning(message: string): void {}
  public error(message: string): void {}
  public config(): void {}
  public destroy(id: number): void {}
  public destroyAll(): void {}
  public setContainer(container: HTMLElement): void {}
}
