import { SurveyAnswers } from '@shared-types/surveys/Interfaces'
import { AnswersTransformer } from '@shared-types/answers/AnswerTransformers'
import { SurveyAnswerTransformer } from './SurveyAnswerTransformer'
import { LoggingService } from '../logging/LoggingService'
import { services } from 'src/services'

export class SurveyAnswersTransformer implements AnswersTransformer {
  private _surveyAnswers: SurveyAnswers
  private log: LoggingService

  public constructor(surveyAnswers: SurveyAnswers) {
    this._surveyAnswers = surveyAnswers
    this.log = services.loggingService
  }

  public get(questionId: string): SurveyAnswerTransformer | null {
    const answer = this._surveyAnswers[questionId]
    if (answer) {
      return new SurveyAnswerTransformer(answer)
    }
    return null
  }

  public raw(): SurveyAnswers {
    return this._surveyAnswers
  }
}
