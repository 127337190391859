import { Observable } from 'rxjs'
import { OptionalUser, OptionalUserWithInitialisation } from './User'

export interface LoginCredentials {
  email: string
  password: string
}

export interface AuthError extends Error {
  type?: 'email-already-in-use'
}

export abstract class UserManagerService {
  public abstract initialise(): Promise<void>
  public abstract dispose(): void
  public abstract getUser(): OptionalUser
  public abstract observeUser(): Observable<OptionalUserWithInitialisation>
  // public abstract observeInitialisedUser(): Observable<OptionalUser>
  public abstract observeErrors(): Observable<AuthError>
  public abstract login(credentials: LoginCredentials): void
  public abstract logout(): void
  public abstract loginWithMicrosoftPopup(): Promise<boolean>
  public abstract loginWithMicrosoftRedirect(): Promise<void>
  public abstract createAnonymousUser(): Promise<void>
  // public abstract refreshUser(): Promise<void>
  public abstract resetPassword(email: string): void
  // public abstract verifyUserEmail(): Promise<void>
  public abstract hasAccess(path: string | readonly string[] | undefined): boolean
}
