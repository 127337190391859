import { BehaviorSubject, Observable } from 'rxjs'
import { PopupService, PopupsType } from './PopupService'
import { Popup } from '@shared-types/popups/Interfaces'

export class RealPopupService extends PopupService {
  private helpPopup: BehaviorSubject<Popup> = new BehaviorSubject<Popup>({ content: null })
  public setPopup(
    popups: PopupsType,
    type: string,
    showPopup: boolean,
    helpPopupsToShow?: string[],
  ): void {
    if (showPopup === false) {
      return
    }
    if (helpPopupsToShow !== undefined && !helpPopupsToShow.includes(type)) {
      return
    }
    if (!Object.keys(popups).includes(type)) {
      return
    }
    const popup = popups[type]
    this.helpPopup.next(popup)
  }
  public clearPopup(): void {
    this.helpPopup.next({ content: null })
  }
  public observePopup(): Observable<Popup> {
    return this.helpPopup
  }
}
