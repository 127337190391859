import * as Sentry from '@sentry/react'
import { CrashReportingService } from './CrashReportingService'
import { LoggingService } from '../logging/LoggingService'

export class SentryCrashReportingService extends CrashReportingService {
  private log: LoggingService

  public constructor(loggingService: LoggingService) {
    super()
    this.log = loggingService
  }

  public async initialise(): Promise<void> {
    this.log.info('SentryCrashReportingService', 'initialise')
    if (import.meta.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: import.meta.env.VITE_SENTRY_RELEASE,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: import.meta.env.VITE_ENVIRONMENT === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: import.meta.env.VITE_ENVIRONMENT === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    } else {
      this.log.info(
        'SentryCrashReportingService',
        'initialise',
        'skipped for non-production environment',
      )
    }
  }

  public dispose(): void {
    this.log.info('SentryCrashReportingService', 'dispose')
  }

  public recordError(error: Error, opts?: { local_msg?: string; context?: any }): void {
    const { local_msg, context } = opts ? opts : { local_msg: undefined, context: undefined }
    const extra = { data: context }
    this.log.info('SentryCrashReportingService', 'recordError', error, local_msg, context)
    Sentry.captureException(error, { tags: { local_msg }, extra })
  }
}
