import { EnhancedStore } from '@reduxjs/toolkit'
import { AggregatedResults, ResultData } from '@shared-types/database/Interfaces'
import {
  Interests,
  MergeFields,
  RegisterCompletionOutput,
  SubscribeOutput,
} from '@shared-types/functions/Interfaces'
import { Survey } from '@shared-types/surveys/Interfaces'
import { ColumnType } from 'antd/lib/table'
import { OrderByDirection } from 'firebase/firestore'
import { Observable } from 'rxjs'
import { OptionalUser, User } from '../auth/User'
import { ResponseFormat } from '../data/converters'
import React from 'react'

export interface SurveyData {
  id: string
}

export interface SurveyResponses {
  data: SurveyResponse[]
  total?: number
}

export interface SurveyResponse {
  [id: string]: QuestionResponse
}

export interface QuestionResponse {
  label?: string
  value: string
}

export interface SurveyResultColumn extends ColumnType<object> {
  title: string
}

export interface ChoiceResults {
  data: ChoiceResult[]
}

export interface ChoiceResult {
  user: string
  questionId: string
  choiceNumber: number
  attrDiffs: AttributeDiff[]
  choice: number
}

export interface AttributeDiff {
  label: string
  diff: number
}

export interface ChoiceResultColumn extends ColumnType<object> {
  title: string
}

export interface Pagination {
  action: 'current' | 'next' | 'previous' | 'start'
  // current?: number
  pageSize: number
  // total?: number
}

export interface SurveyResultsParams {
  surveyName?: string
  pagination: Pagination
  sorter: OrderBy
  filter: FilterBy | null
}

export interface OrderBy {
  column: string
  direction: OrderByDirection
}

export type FilterByOperator = '==' | '<' | '<=' | '>' | '>='

export interface FilterBy {
  column: string
  value: React.Key | boolean
  operator: FilterByOperator
}

export abstract class DatabaseManagerService {
  public abstract subscribeToStore(
    store: EnhancedStore,
    surveyName: string,
    user: OptionalUser,
  ): Promise<void>
  public abstract getSurveys(user: User): Promise<SurveyData[]>
  public abstract observeSurveys(user: User): Observable<SurveyData[]>
  // public abstract getSurveyResultColumns(survey: string): Promise<SurveyResultColumn[]>
  public abstract observeResponseCount(): Observable<number>
  public abstract observeResponses(): Observable<ResultData[]>
  public abstract getResponses(
    survey: string,
    pagination?: Pagination,
    format?: ResponseFormat,
  ): Promise<ResultData[]>
  public abstract getResponseCountAggregate(
    survey: string,
    sorter: OrderBy,
    filter: FilterBy | null,
  ): Promise<number>
  public abstract getChoiceData(survey: string): Promise<[ChoiceResults, ChoiceResultColumn[]]>
  public abstract setCurrentSurvey(surveyName: string | undefined): void
  public abstract setPagination(pagination: Pagination): void
  public abstract setOrderBy(sorter: OrderBy): void
  public abstract setFilterBy(filter: FilterBy | null): void
  public abstract observeSurveyResultsParams(): Observable<SurveyResultsParams>
  public abstract activateSurvey(surveyName: string): Promise<void>
  public abstract addSurveyOwner(email: string, surveyName: string): Promise<void>
  public abstract observeSurveyOwners(surveyName: string): Observable<string[]>
  public abstract getSurveyOwners(surveyName: string): Promise<string[]>
  public abstract refreshSurveys(surveys: Survey[]): Promise<void>
  public abstract isActive(survey: string): Promise<boolean>
  public abstract observeErrors(): Observable<Error>
  public abstract getAggregatedResults(surveyName: string): Promise<AggregatedResults>
  public abstract observeAggregatedResults(surveyName: string): Observable<AggregatedResults>
  public abstract clearAggregateResults(surveyName: string): Promise<void>
  public abstract registerCompletionMailchimp(
    surveyName: string,
    panelId: string,
  ): Promise<RegisterCompletionOutput>
  public abstract subscribeMailchimp(
    email_address: string,
    merge_fields?: MergeFields,
    interests?: Interests,
    tags?: string[],
  ): Promise<SubscribeOutput>
  public abstract archiveSubmissions(surveyName: string, submissionIds: string[]): Promise<void>
}
