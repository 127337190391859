import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Router from './App'
import 'normalize.css'

// import 'antd/dist/antd.min.css'

import './css/index.css'
// import * as serviceWorker from './serviceWorker'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(<Router />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
