import { CrashReportingService } from '../crash/CrashReportingService'

export abstract class LoggingService {
  public abstract initialise(crashReportingService: CrashReportingService): Promise<void>
  public abstract dispose(): void
  public abstract debug(...msg: any[]): void
  public abstract log(...msg: any[]): void
  public abstract info(...msg: any[]): void
  public abstract warn(...msg: any[]): void
  public abstract error(msg: string, opts?: { error?: Error; context?: any }): void
}
