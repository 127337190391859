export type OptionalUser = User | undefined
export interface OptionalUserWithInitialisation {
  user: OptionalUser
  initialised: boolean
}
// export interface UserProfile {
//   name: string
//   isAdmin: () => boolean
//   emailVerified: () => boolean
// }

export interface User {
  id: string
  email: string | null
  isAdmin: boolean
  anonymous: boolean
  lastActive?: Date
  created?: Date
}

export function newUser(id: string, email: string, anonymous: boolean): User {
  return {
    id,
    email,
    isAdmin: false,
    anonymous,
  }
}

// export class User {
//   public id: string
//   public email: string
//   private _emailVerified: boolean
//   constructor(id: string, email: string) {
//     this.id = id
//     this.email = email
//     this._emailVerified = false
//   }

//   // public getProfile(): UserProfile {
//   //   return { name: '' }
//   // }

//   public hasAccess(path: string | readonly string[]): boolean {
//     return true
//   }

//   public isAdmin(): boolean {
//     return true
//   }

//   public emailVerified(): boolean {
//     return this._emailVerified
//   }

//   public setEmailVerified(verified: boolean) {
//     this._emailVerified = verified
//   }
// }
