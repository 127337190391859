import { FirebaseApp, initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

// I don't think this is used anywhere
export const actionCodeSettings = {
  url: `${import.meta.env.VITE_FIREBASE_DATABASE_URL}/home`,
  handleCodeInApp: true,
}

// I don't think this is used anywhere
export const functionsUrl = import.meta.env.VITE_FUNCTIONS_URL

// Initialize Firebase
function initializeFirebase(): FirebaseApp {
  const app = initializeApp(firebaseConfig)
  initializeFirestore(app, { experimentalAutoDetectLongPolling: true })
  return app
}

export default initializeFirebase
