import { AnalyticsService } from './AnalyticsService'

export class MockAnalyticsService extends AnalyticsService {
  public async initialise(): Promise<void> {
    return
  }

  public dispose(): void {
    return
  }

  public logEvent(name: string, params: Object): void {
    console.log(`AnalyticsService.logEvent: ${name}, ${JSON.stringify(params)}`)
    return
  }
}
