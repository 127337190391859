export interface Messager {
  info(message: string): void
  success(message: string): void
  error(message: string): void
  config(messagerConfig: MessagerConfig): void
  destroy(): void
}

export abstract class MessagerService {
  public abstract info(message: string): void
  public abstract success(message: string): void
  public abstract warning(message: string): void
  public abstract error(message: string): void
  public abstract config(messagerConfig: MessagerConfig): void
  public abstract destroy(id: number): void
  public abstract destroyAll(): void
  public abstract setContainer(container: HTMLElement): void
}

export interface MessagerConfig {
  duration: number
  maxCount: number
}
