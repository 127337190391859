import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Question, QuestionType } from '@shared-types/questions/Interfaces'
import { SurveyAnswer, SurveyAnswers } from '@shared-types/surveys/Interfaces'
import { getSecondsBetween } from 'src/components/helperFunctions'
import { SurveyAnswersTransformer } from '../answers/SurveyAnswersTransformer'
import { SurveyAnswerTransformer } from '../answers/SurveyAnswerTransformer'

type QuestionState = {
  qid: string | null
  questionType?: QuestionType
}

export type SurveyReduxState = {
  surveyId: string | null
  submissionId: string | null
  currentQuestionId: string | null
  currentQuestionType?: QuestionType
  userAnswers: SurveyAnswers
  /** The working answer for the current question. Only undefined before initialisation */
  currentAnswer?: SurveyAnswer
  questionHistory: QuestionState[]
  timeOnCurrentQuestion: string
  // error: () => ({ type: ERROR }),
  isSubmitClicked: boolean
  direction: 'forwards' | 'backwards'
}

export const initialState: SurveyReduxState = {
  surveyId: null,
  submissionId: null,
  currentQuestionId: null,
  currentQuestionType: undefined,
  userAnswers: {},
  currentAnswer: undefined,
  questionHistory: [],
  timeOnCurrentQuestion: '',
  // error: () => ({ type: ERROR }),
  // log: services.loggingService,
  isSubmitClicked: false,
  direction: 'forwards',
}

const getQuestionState = (state: SurveyReduxState): QuestionState => {
  return {
    qid: state.currentQuestionId,
    questionType: state.currentQuestionType,
  }
}

function getAnswerId(questionId: string | null) {
  if (questionId === null) {
    return ''
  }
  return questionId
}

interface InitialisePayload {
  questions: Question[]
  surveyId: string
  startQuestionId?: string
}

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    initialise: (state, action: PayloadAction<InitialisePayload>) => {
      let firstQuestion
      // If we are initialising a new survey, reset the state
      if (state.surveyId !== null && state.surveyId !== action.payload.surveyId) {
        firstQuestion = action.payload.startQuestionId
          ? action.payload.questions.find(
              (question) => question.id === action.payload.startQuestionId,
            ) || action.payload.questions[0]
          : action.payload.questions[0]
        return {
          ...initialState,
          surveyId: action.payload.surveyId,
          currentQuestionId: firstQuestion.id,
          currentQuestionType: firstQuestion.type,
          currentAnswer: {
            questionId: firstQuestion.id,
            questionType: firstQuestion.type,
            value: null,
            qStartTime: new Date().toUTCString(),
          },
        }
      }
      // If we are initialising the same survey, keep the state
      if (state.currentQuestionId !== null) {
        firstQuestion =
          action.payload.questions.find((question) => question.id === state.currentQuestionId) ||
          action.payload.questions[0]
      } else if (action.payload.startQuestionId) {
        firstQuestion =
          action.payload.questions.find(
            (question) => question.id === action.payload.startQuestionId,
          ) || action.payload.questions[0]
      } else {
        firstQuestion = action.payload.questions[0]
      }
      const currentAnswer = state.currentAnswer ?? {
        questionId: firstQuestion.id,
        questionType: firstQuestion.type,
        value: null,
        qStartTime: new Date().toUTCString(),
      }
      return {
        ...state,
        surveyId: action.payload.surveyId,
        currentQuestionId: firstQuestion.id,
        currentQuestionType: firstQuestion.type,
        currentAnswer,
      }
    },
    setSubmissionId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        submissionId: action.payload,
      }
    },
    goToNextQuestion: (state, action: PayloadAction<Question[]>) => {
      let nextQid: string | null
      const question = action.payload.find((q) => q.id === state.currentQuestionId)
      if (!question) {
        return state
      }
      if (question.next) {
        nextQid = question.next({ answers: new SurveyAnswersTransformer(state.userAnswers) })
      } else {
        const nextIndex = action.payload.findIndex((q) => q.id === state.currentQuestionId) + 1
        if (nextIndex >= action.payload.length) {
          nextQid = state.currentQuestionId
        } else {
          nextQid = action.payload[nextIndex].id
        }
      }
      return {
        ...state,
        currentQuestionId: nextQid,
        currentQuestionType: action.payload.find((q) => q.id === nextQid)?.type,
        questionHistory: [getQuestionState(state)].concat(state.questionHistory),
        currentAnswer: undefined,
        direction: 'forwards',
      }
    },
    goToPreviousQuestion: (state) => {
      if (state.questionHistory.length > 0) {
        return {
          ...state,
          currentQuestionId: state.questionHistory[0].qid,
          currentQuestionType: state.questionHistory[0].questionType,
          questionHistory: state.questionHistory.slice(1),
          currentAnswer: undefined,
          direction: 'backwards',
        }
      }
      return state
    },
    updateAnswer: (state) => {
      if (state.currentAnswer) {
        const currentTime = new Date().toUTCString()
        const currentTimeElapsed = getSecondsBetween(state.currentAnswer?.qStartTime, currentTime)
        const savedAnswer: SurveyAnswer = {
          ...state.currentAnswer,
          qEndTime: currentTime,
          timeTaken: state.currentAnswer?.timeTaken
            ? state.currentAnswer?.timeTaken + currentTimeElapsed
            : currentTimeElapsed,
        }
        const answerId = getAnswerId(state.currentQuestionId)
        return {
          ...state,
          userAnswers: {
            ...state.userAnswers,
            [answerId]: savedAnswer,
          },
          currentAnswer: undefined,
        }
      }
      return state
    },
    recordAnswer: (state) => {
      if (state.currentAnswer && state.currentQuestionId !== null) {
        const currentTime = new Date().toUTCString()
        const currentTimeElapsed = getSecondsBetween(state.currentAnswer?.qStartTime, currentTime)
        const savedAnswer: SurveyAnswer = {
          ...state.currentAnswer,
          qEndTime: currentTime,
          timeTaken: state.currentAnswer?.timeTaken
            ? state.currentAnswer?.timeTaken + currentTimeElapsed
            : currentTimeElapsed,
        }
        return {
          ...state,
          userAnswers: {
            ...state.userAnswers,
            [state.currentQuestionId]: savedAnswer,
          },
        }
      }
      return state
    },
    retrieveAnswer: (state, action: PayloadAction<Question[]>) => {
      const answerId = getAnswerId(state.currentQuestionId)
      if (answerId in state.userAnswers) {
        const prevAnswer = state.userAnswers[answerId]
        return {
          ...state,
          currentAnswer: {
            ...prevAnswer,
            qStartTime: new Date().toUTCString(),
          },
        }
      } else {
        const question = action.payload.find((q) => q.id === state.currentQuestionId)
        if (question) {
          return {
            ...state,
            currentAnswer: {
              questionId: question.id,
              questionType: question.type,
              value: null,
              qStartTime: new Date().toUTCString(),
            },
          }
        }
      }
      return state
    },
    injectCurrent: (state, action: PayloadAction<Partial<SurveyAnswer>>) => {
      if (state.currentAnswer) {
        return {
          ...state,
          currentAnswer: { ...state.currentAnswer, ...action.payload },
        }
      }
      return state
    },
    updateCurrent: (state, action: PayloadAction<SurveyAnswer>) => {
      return {
        ...state,
        currentAnswer: action.payload,
        currentQuestionId: action.payload.questionId,
      }
    },
    onSubmitClicked: (state) => {
      return {
        ...state,
        isSubmitClicked: true,
      }
    },
  },
})

export const {
  initialise,
  setSubmissionId,
  goToNextQuestion,
  goToPreviousQuestion,
  updateAnswer,
  retrieveAnswer,
  injectCurrent,
  updateCurrent,
  recordAnswer,
  onSubmitClicked,
} = surveySlice.actions

export default surveySlice.reducer
