export const parseOnlyLetterAndSpace = (expression: string) => expression.replace(/[^A-ZA-z]/g, '')
export const parseLength = (expression: string, length: number) => expression.substring(0, length)
export const checkAtLength = (expression: string, length: number) =>
  expression && expression.trim().length <= length
export const checkEmailPattern = (mail: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(mail)
}

/** Return time difference in seconds between two date strings */
export const getSecondsBetween = (subStarted: string | undefined, subEnded: string): number => {
  if (subStarted === undefined) {
    return 0
  }
  const submissionStarted = new Date(subStarted)
  const submissionEnded = new Date(subEnded)
  const timeTaken = submissionEnded.getTime() - submissionStarted.getTime()

  if (isNaN(timeTaken)) {
    return 0
  }
  return timeTaken / 1000
}

/** return type of user device based on userAgent */
export const getUserDeviceType = (): string => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}
