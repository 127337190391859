import { Survey } from '@shared-types/surveys/Interfaces'
import { OptionalUser, User } from '../auth/User'
import {
  ChoiceResultColumn,
  ChoiceResults,
  DatabaseManagerService,
  Pagination,
  OrderBy,
  SurveyData,
  SurveyResultColumn,
  SurveyResultsParams,
  FilterBy,
} from './DatabaseManagerService'
import { Observable, BehaviorSubject, Subject } from 'rxjs'
import { EnhancedStore } from '@reduxjs/toolkit'
import { AnyAction, Middleware, Dispatch } from 'redux'
import { AggregatedResults, ResultData } from '@shared-types/database/Interfaces'
import {
  Interests,
  MergeFields,
  RegisterCompletionOutput,
  SubscribeOutput,
} from '@shared-types/functions/Interfaces'
import { LoggingService } from '../logging/LoggingService'
import { MockLoggingService } from '../logging/MockLoggingService'
export class MockDatabaseManagerService extends DatabaseManagerService {
  public getChoiceData(survey: string): Promise<[ChoiceResults, ChoiceResultColumn[]]> {
    throw new Error('Method not implemented.')
  }
  public observeAggregatedResults(surveyName: string): Observable<AggregatedResults> {
    throw new Error('Method not implemented.')
  }
  public getAggregatedResults(surveyName: string): Promise<AggregatedResults> {
    throw new Error('Method not implemented.')
  }
  private surveyOwnersSubjects: { [key: string]: BehaviorSubject<string[]> }
  private errorSubject: Subject<Error> = new Subject<Error>()
  private surveyStates: any
  private currentPage: number
  private log: LoggingService
  public constructor() {
    super()
    this.currentPage = 1
    this.surveyStates = {
      firstSurvey: {
        questions: [
          { id: 'q1', header: 'Question 1' },
          { id: 'q2', header: 'Question 2' },
        ],
        responses: [
          { q1: { value: 'test1' }, q2: { value: 'test2' } },
          { q1: { value: 'test3' }, q2: { value: 'test4' } },
          { q1: { value: 'test5' }, q2: { value: 'test6' } },
          { q1: { value: 'test1' }, q2: { value: 'test2' } },
          { q1: { value: 'test3' }, q2: { value: 'test4' } },
          { q1: { value: 'test5' }, q2: { value: 'test6' } },
          { q1: { value: 'test1' }, q2: { value: 'test2' } },
          { q1: { value: 'test3' }, q2: { value: 'test4' } },
          { q1: { value: 'test5' }, q2: { value: 'test6' } },
          { q1: { value: 'test1' }, q2: { value: 'test2' } },
          { q1: { value: 'test3' }, q2: { value: 'test4' } },
          { q1: { value: 'test5' }, q2: { value: 'test6' } },
          { q1: { value: 'test1' }, q2: { value: 'test2' } },
          { q1: { value: 'test3' }, q2: { value: 'test4' } },
          { q1: { value: 'test5' }, q2: { value: 'test6' } },
          { q1: { value: 'test1' }, q2: { value: 'test2' } },
          { q1: { value: 'test3' }, q2: { value: 'test4' } },
          { q1: { value: 'test5' }, q2: { value: 'test6' } },
        ],
        owners: [],
        active: false,
      },
      secondSurvey: {
        questions: [
          { id: 'q1', header: 'Hello' },
          { id: 'q2', header: 'World' },
        ],
        responses: [
          { q1: { value: 'Hi' }, q2: { value: 'Earth' } },
          { q1: { value: 'Hello' }, q2: { value: 'Venus' } },
          { q1: { value: 'Hey' }, q2: { value: 'Mars' } },
        ],
        owners: [],
        active: false,
      },
    }
    this.surveyOwnersSubjects = Object.fromEntries(
      Object.entries(this.surveyStates).map(([key, survey]: [string, any]) => [
        key,
        new BehaviorSubject(survey.owners),
      ]),
    )
    this.log = new MockLoggingService()
  }

  public async subscribeToStore(
    store: EnhancedStore<any, AnyAction, readonly Middleware<{}, any, Dispatch<AnyAction>>[]>,
    surveyName: string,
    user: OptionalUser,
  ): Promise<void> {
    this.log.info('subscribeToStore', surveyName, user)
  }

  public async addSurveyOwner(email: string, surveyName: string): Promise<void> {
    this.log.info('addSurveyOwner', email, surveyName)
    await new Promise((r) => setTimeout(r, 500))
    this.surveyStates[surveyName].owners.push(email)
    this.surveyOwnersSubjects[surveyName].next([...this.surveyStates[surveyName].owners])
  }
  public observeSurveyOwners(surveyName: string): Observable<string[]> {
    return this.surveyOwnersSubjects[surveyName]
  }

  public async getSurveyOwners(surveyName: string): Promise<string[]> {
    return this.surveyOwnersSubjects[surveyName].value
  }

  public async refreshSurveys(surveys: Survey[]): Promise<void> {
    this.log.info('refreshSurveys')
    await new Promise((r) => setTimeout(r, 500))

    return
  }
  public async getSurveys(user: User): Promise<SurveyData[]> {
    return Object.keys(this.surveyStates).map((key) => ({ id: key }))
  }

  public observeSurveys(user: User): Observable<SurveyData[]> {
    return new BehaviorSubject(Object.keys(this.surveyStates).map((key) => ({ id: key })))
  }

  public async activateSurvey(surveyName: string) {
    this.log.info('activateSurvey ', surveyName)
    await new Promise((r) => setTimeout(r, 500))
    this.surveyStates[surveyName].active = true
  }

  public async isActive(surveyName: string) {
    return true
    // return this.surveyStates[surveyName].active
  }

  public async getSurveyResultColumns(survey: string): Promise<SurveyResultColumn[]> {
    return Object.values(this.surveyStates[survey].questions).map((value: any) => ({
      title: value.header,
      dataIndex: value.id,
      key: value.id,
    }))
  }

  public observeResponseCount(): Observable<number> {
    throw new Error('Method not implemented.')
  }

  public observeResponses(): Observable<ResultData[]> {
    throw new Error('Method not implemented.')
  }

  public async getResponses(
    surveyName: string,
    pagination: Pagination,
    // ): Promise<[SurveyResponses, SurveyResultColumn[]]> {
  ): Promise<ResultData[]> {
    // const current = pagination.current ? pagination.current : 1
    switch (pagination.action) {
      case 'next':
        this.currentPage = this.currentPage + 1
        break
      case 'previous':
        this.currentPage = this.currentPage - 1
        break
    }
    const pageSize = pagination.pageSize ? pagination.pageSize : 10
    const start = (this.currentPage - 1) * pageSize
    const stop = start + pageSize
    const resultData = this.surveyStates[surveyName].responses.slice(start, stop)
    return resultData as ResultData[]
  }

  public getResponseCountAggregate(
    surveyName: string,
    sorter: OrderBy,
    filter: FilterBy | null,
  ): Promise<number> {
    throw new Error('Method not implemented.')
  }

  public setCurrentSurvey(surveyName: string): void {
    throw new Error('Method not implemented.')
  }

  public setPagination(pagination: Pagination): void {
    throw new Error('Method not implemented.')
  }

  public setOrderBy(sorter: OrderBy): void {
    throw new Error('Method not implemented.')
  }

  public setFilterBy(filter: FilterBy): void {
    throw new Error('Method not implemented.')
  }

  public observeSurveyResultsParams(): Observable<SurveyResultsParams> {
    throw new Error('Method not implemented.')
  }

  public observeErrors(): Observable<Error> {
    return this.errorSubject
  }

  public async clearAggregateResults(surveyName: string): Promise<void> {
    throw new Error('Method not implemented.')
  }

  public async registerCompletionMailchimp(
    surveyName: string,
    panelId: string,
  ): Promise<RegisterCompletionOutput> {
    throw new Error('Method not implemented.')
  }

  public async subscribeMailchimp(
    email_address: string,
    merge_fields?: MergeFields,
    interests?: Interests,
    tags?: string[],
  ): Promise<SubscribeOutput> {
    throw new Error('Method not implemented.')
  }

  public async archiveSubmissions(surveyName: string, submissionIds: string[]): Promise<void> {
    throw new Error('Method not implemented.')
  }
}
