import { isValidElement } from 'react'

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function waitAtLeast<T>(promise: Promise<T>, ms: number) {
  const result = await Promise.all([promise, sleep(ms)])
  return result[0]
}

export function maybeMergeStrings(strings: string | string[], sep: string) {
  if (Array.isArray(strings)) {
    return strings.join(sep)
  } else {
    return strings
  }
}

export function randomElement(array: any[]) {
  return array[Math.floor(Math.random() * array.length)]
}

export function assertNever(x: never): never {
  throw new Error('Switch statement is not exhaustive')
}

export function assertUnique<T>(array: T[], message: string) {
  if (array.length !== new Set(array).size) {
    throw new Error(message)
  }
}

export function hexToRgb(hex: string) {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)
  return { r, g, b }
}

export function toPrecision(value: number, precision: number): string {
  if (value === 0) {
    return '0'
  }

  // Calculate the number of digits in the integer part of the number
  const integerPartDigits = Math.floor(Math.log10(Math.abs(value))) + 1

  // Calculate the number of decimal places needed to achieve the desired precision
  const decimalPlaces = Math.max(0, precision - integerPartDigits)

  // Round the number to the specified precision
  const multiplier = Math.pow(10, decimalPlaces)
  const rounded = Math.round(value * multiplier) / multiplier

  // Convert the number to a string and split it into the integer and decimal parts
  let [integerPart, decimalPart = ''] = rounded.toString().split('.')

  // If the precision is greater than the length of the decimal part, append zeros
  while (decimalPart.length < decimalPlaces) {
    decimalPart += '0'
  }

  // Remove trailing zeros from the decimal part
  decimalPart = decimalPart.replace(/0+$/, '')

  // If there's no decimal part left after removing trailing zeros, just return the integer part
  if (decimalPart.length === 0) {
    return integerPart
  }

  // Otherwise, return the number as a string with the minimum required decimal places
  return `${integerPart}.${decimalPart}`
}

export function isRecord(value: unknown): value is Record<string, unknown> {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    !(value instanceof Date) &&
    !(value instanceof RegExp) &&
    !isValidElement(value)
  )
}
