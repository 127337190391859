import { UserRecord } from 'firebase-functions/v1/auth'
import { FirebaseApp } from 'firebase/app'
import {
  Auth,
  User as FirebaseUser,
  IdTokenResult,
  Unsubscribe,
  getAuth,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'
import { Functions, getFunctions } from 'firebase/functions'
import { BehaviorSubject, Subject } from 'rxjs'
import adminPaths from '../../components/dashboard/paths'
import { appConnectAuthEmulator, appConnectFunctionsEmulator } from '../firebase/emulators'
import { OptionalUser, OptionalUserWithInitialisation, User } from './User'
import { AuthError, LoginCredentials, UserManagerService } from './UserManagerService'
import { LoggingService } from '../logging/LoggingService'

export class FirebaseUserManagerService extends UserManagerService {
  private functions: Functions
  private userSubject: BehaviorSubject<OptionalUserWithInitialisation>
  // private initialisedUserSubject: Subject<OptionalUser> = new Subject<OptionalUser>()
  private errorSubject: Subject<AuthError> = new Subject()
  private auth: Auth
  private authUnsubscribe?: Unsubscribe
  private log: LoggingService
  private provider: OAuthProvider

  public constructor(firebaseApp: FirebaseApp, loggingService: LoggingService) {
    super()
    this.auth = getAuth(firebaseApp)
    this.functions = getFunctions(firebaseApp)
    this.log = loggingService
    this.log.info('VITE_USE_EMULATORS', import.meta.env.VITE_USE_EMULATORS)
    this.log.info('VITE_AZURE_TENANT_ID', import.meta.env.VITE_AZURE_TENANT_ID)
    if (import.meta.env.VITE_USE_EMULATORS) {
      appConnectAuthEmulator(this.auth)
      appConnectFunctionsEmulator(this.functions)
    }
    this.userSubject = new BehaviorSubject<OptionalUserWithInitialisation>({
      user: undefined,
      initialised: false,
    })
    this.provider = new OAuthProvider('microsoft.com')
    this.provider.setCustomParameters({ tenant: import.meta.env.VITE_AZURE_TENANT_ID ?? '' })
  }

  public async initialise(): Promise<void> {
    this.log.info('FirebaseUserManagerService', 'initialise')

    this.authUnsubscribe = this.auth.onAuthStateChanged((user) => this.updateCurrentUser(user))
    this.log.info('FirebaseUserManagerService', 'initialised')
  }

  public dispose(): void {
    if (this.authUnsubscribe) {
      this.authUnsubscribe()
    }
  }

  private async updateCurrentUser(user: FirebaseUser | null) {
    this.log.info('updateCurrentUser', user)
    if (user === null) {
      this.userSubject.next({
        user: undefined,
        initialised: true,
      })
    } else {
      // await user.reload()
      try {
        const idTokenResult = await user.getIdTokenResult(true)
        this.log.info('idTokenResult', idTokenResult)
        this.userSubject.next({
          user: this.getOptionalUserFromFirebaseUser(user, idTokenResult),
          initialised: true,
        })
      } catch (e) {
        this.userSubject.error(e as Error)
        this.recordError(e as Error, 'Failed to connect to Firebase  to check user token.')
      }
    }
  }

  private async updateCurrentUserFromUserRecord(user: UserRecord) {
    this.log.info('updateCurrentUserFromUserRecord', user)
    this.userSubject.next({
      user: this.getUserFromUserRecord(user),
      initialised: true,
    })
  }

  private recordError(error: Error, local_msg?: string) {
    this.log.error(`Auth error. ${local_msg}`, { error })
    // this.userSubject.error(error)
    this.errorSubject.next(error)
  }

  public getUser(): OptionalUser {
    this.log.info('getUser')
    if (this.userSubject.value.initialised) {
      return this.userSubject.value.user
    } else {
      throw new Error('User is not yet initialised')
    }
  }

  public observeUser() {
    this.log.info('observeUser')
    return this.userSubject
  }

  public observeErrors() {
    this.log.info('observeErrors')
    return this.errorSubject
  }

  public async login(credentials: LoginCredentials) {
    this.log.info('login')
    try {
      await signInWithEmailAndPassword(this.auth, credentials.email, credentials.password)
      // const token = await user.getIdTokenResult()
      // this.userSubject.next(this.getOptionalUserFromFirebaseUser(user, token))
    } catch (e) {
      const error = e as Error
      this.log.error('Failed to log in.', { error })
      this.errorSubject.next(error)
    }
  }

  public async logout() {
    this.log.info('logout')
    if (this.getUser()) {
      try {
        await signOut(this.auth)
        // this.userSubject.next(undefined)
      } catch (e) {
        const error = e as Error
        this.log.error('Error attempting to log out.', { error })
      }
    } else {
      this.log.error('Tried to logout but no user logged in.')
    }
  }

  public async loginWithMicrosoftPopup(): Promise<boolean> {
    this.log.info('loginWithMicrosoft')
    try {
      const credential = await signInWithPopup(this.auth, this.provider)
      const tokenResult = await credential.user.getIdTokenResult()
      if (typeof tokenResult.claims.admin !== 'boolean') {
        throw new Error('Admin claim is not a boolean')
      }
      return tokenResult.claims.admin
    } catch (e) {
      this.log.error('Error logging in with Microsoft.', { context: e })
      throw e
    }
  }

  public async loginWithMicrosoftRedirect(): Promise<void> {
    this.log.info('loginWithMicrosoftRedirect')
    try {
      await signInWithRedirect(this.auth, this.provider)
    } catch (e) {
      this.recordError(e as Error, 'Error logging in with Microsoft.')
    }
  }

  private getOptionalUserFromFirebaseUser(
    firebaseUser: FirebaseUser,
    token?: IdTokenResult,
  ): OptionalUser {
    return {
      id: firebaseUser.uid,
      email: firebaseUser.email,
      isAdmin: token ? !!token.claims.admin : false,
      anonymous: firebaseUser.isAnonymous,
      lastActive: firebaseUser.metadata.lastSignInTime
        ? new Date(Date.parse(firebaseUser.metadata.lastSignInTime))
        : undefined,
      created: firebaseUser.metadata.creationTime
        ? new Date(Date.parse(firebaseUser.metadata.creationTime))
        : undefined,
    }
  }

  private getUserFromUserRecord(userRecord: UserRecord): User {
    return {
      id: userRecord.uid,
      email: userRecord.email ? userRecord.email : null,
      isAdmin: userRecord.customClaims?.admin,
      anonymous: false,
      lastActive: userRecord.metadata.lastSignInTime
        ? new Date(Date.parse(userRecord.metadata.lastSignInTime))
        : undefined,
      created: userRecord.metadata.creationTime
        ? new Date(Date.parse(userRecord.metadata.creationTime))
        : undefined,
    }
  }

  // public async createUser(credentials: LoginCredentials): Promise<void> {
  //   if (credentials.password === '') {
  //     this.recordError(new Error('Password cannot be empty'), 'Password cannot be empty')
  //     return
  //   }
  //   try {
  //     await createUserWithEmailAndPassword(this.auth, credentials.email, credentials.password)
  //     const { data } = await this.assignUserRole()
  //     this.log.info('createUser', data)
  //     if (data) {
  //       this.updateCurrentUserFromUserRecord(data)
  //     }
  //   } catch (e) {
  //     this.recordError(e as Error, 'Error creating new user.')
  //   }
  // }

  // public async refreshUser(): Promise<void> {
  //   log.info('refreshUser', this.auth.currentUser)
  //   try {
  //     if (this.auth.currentUser) await this.updateCurrentUser(this.auth.currentUser)
  //   } catch (e) {
  //     this.recordError(e as Error, 'Error refreshing user')
  //   }
  // }

  public async createAnonymousUser(): Promise<void> {
    this.log.info('createAnonymousUser')
    try {
      await signInAnonymously(this.auth)
    } catch (e) {
      this.recordError(e as Error, 'Error creating new anonymous user.')
    }
  }

  private createUserProfile(user: User) {
    // const userUid = res.user.uid
    //     const db = firebase.firestore()
    //     // setUser(userUid)
    //     console.log('user', userUid)
    //     db.collection('/users')
    //       .doc(userUid)
    //       .set({
    //         name: inputs.name,
    //         emailAddress: inputs.email,
    //         uid: userUid,
    //       })
  }
  // public async verifyUserEmail() {
  //   try {
  //     const user = this.auth.currentUser
  //     if (user) {
  //       await sendEmailVerification(user)
  //     } else {
  //       throw new Error('Tried to send email verification, but no user loggedn in.')
  //     }
  //   } catch (e) {
  //     this.recordError(e as Error)
  //   }
  // }

  public resetPassword(email: string): void {
    const emailSentSuccessfully = true
    if (emailSentSuccessfully) {
      this.errorSubject.next(new Error('Password reset email sent'))
    } else {
      this.errorSubject.next(new Error('Error sending password reset email'))
    }
  }

  public hasAccess(path: string | readonly string[] | undefined): boolean {
    if (this.userSubject.value.initialised) {
      const user = this.userSubject.value.user
      switch (path) {
        case adminPaths.dashboard:
          return !!user && !user.anonymous
        default:
          return false
      }
    }
    return false
  }
}
