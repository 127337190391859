export abstract class AnalyticsService {
  protected completionLogged: Record<string, boolean> = {}

  public abstract initialise(): Promise<void>
  public abstract dispose(): void
  public abstract logEvent(name: string, params: Object): void
  public logCompletion(surveyName: string): void {
    if (this.completionLogged[surveyName]) {
      console.log(`AnalyticsService.logCompletion: ${surveyName} (already logged)`)
    } else {
      console.log(`AnalyticsService.logCompletion: ${surveyName}`)
      this.logEvent('survey_complete', {
        surveyName: surveyName,
      })
      this.completionLogged[surveyName] = true
    }
  }
}
