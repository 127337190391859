import { BehaviorSubject, Subject } from 'rxjs'
import { User, OptionalUser, newUser, OptionalUserWithInitialisation } from './User'
import { UserManagerService, AuthError, LoginCredentials } from './UserManagerService'
import { LoggingService } from '../logging/LoggingService'
import { MockLoggingService } from '../logging/MockLoggingService'

export class MockUserManagerService extends UserManagerService {
  private log: LoggingService

  public constructor() {
    super()
    this.log = new MockLoggingService()
  }

  public async refreshUser(): Promise<void> {}
  public async initialise(): Promise<void> {
    return
  }
  public dispose(): void {
    return
  }
  public async createAnonymousUser(): Promise<void> {
    this.userSubject.next({
      user: newUser('xyz', 'anonymous', true),
      initialised: true,
    })
  }
  public hasAccess(path: string | readonly string[] | undefined): boolean {
    return true
  }
  private userSubject: BehaviorSubject<OptionalUserWithInitialisation> =
    new BehaviorSubject<OptionalUserWithInitialisation>({ user: undefined, initialised: false })
  private errorSubject: Subject<AuthError> = new Subject()

  public getUser(): OptionalUser {
    if (this.userSubject.value.initialised) {
      return this.userSubject.value.user
    } else {
      throw new Error('User not initialised')
    }
  }

  public observeUser() {
    console.log('Observing user')
    return this.userSubject
  }

  public observeInitialisedUser() {
    console.log('Observing user')
    return this.userSubject
  }

  public observeErrors() {
    return this.errorSubject
  }

  public async login(credentials: LoginCredentials) {
    if (credentials.password === '123') {
      this.userSubject.next({
        user: newUser('testuser', credentials.email, false),
        initialised: true,
      })
    } else {
      this.errorSubject.next(new Error('Email address or password incorrect. Please try again.'))
    }
  }

  public async logout() {
    this.userSubject.next({ user: undefined, initialised: true })
  }

  public async loginWithMicrosoftPopup(): Promise<boolean> {
    throw new Error('Method not implemented.')
  }

  public async loginWithMicrosoftRedirect(): Promise<void> {
    throw new Error('Method not implemented.')
  }

  // public async createUser(credentials: LoginCredentials) {
  //   const user = newUser('testuser', credentials.email, false)
  //   if (credentials.password === '123') {
  //     this.userSubject.next({ user, initialised: true })
  //   } else {
  //     this.errorSubject.next(new Error('Some sort of error creating account'))
  //   }
  //   this.createUserProfile(user)
  //   this.verifyUserEmail()
  // }

  private createUserProfile(user: User) {
    // const userUid = res.user.uid
    //     const db = firebase.firestore()
    //     // setUser(userUid)
    //     console.log('user', userUid)
    //     db.collection('/users')
    //       .doc(userUid)
    //       .set({
    //         name: inputs.name,
    //         emailAddress: inputs.email,
    //         uid: userUid,
    //       })
  }
  // public async verifyUserEmail() {
  //   const user = this.getUser()
  //   this.log.info('verifyUserEmail', user?.email)
  //   await new Promise((r) => setTimeout(r, 500))
  //   if (user) {
  //     this.userSubject.next({ user: { ...user, emailVerified: true }, initialised: true })
  //   } else {
  //     throw new Error('Failed to verify email as user not found.')
  //   }
  // }

  public resetPassword(email: string): void {
    const emailSentSuccessfully = true
    if (emailSentSuccessfully) {
      this.errorSubject.next(new Error('Password reset email sent'))
    } else {
      this.errorSubject.next(new Error('Error sending password reset email'))
    }
  }
}
