import { AnswerTransformer } from '@shared-types/answers/AnswerTransformers'
import { AnswerType } from '@shared-types/answers/AnswerTypes'
import { QuestionType } from '@shared-types/questions/Interfaces'
import { AnswerValue, GeneralisedAnswerValue, SurveyAnswer } from '@shared-types/surveys/Interfaces'
import { isSingleAnswerValue } from '../question/utils'
import { LoggingService } from '../logging/LoggingService'
import { services } from 'src/services'

export class SurveyAnswerTransformer implements AnswerTransformer {
  private _answer: SurveyAnswer
  private log: LoggingService

  public constructor(answer: SurveyAnswer) {
    this._answer = answer
    this.log = services.loggingService
  }

  public convertedToQuestionType(questionType: QuestionType): AnswerTransformer {
    if (this._answer.questionType === questionType) {
      return this
    }
    return new SurveyAnswerTransformer({
      ...this._answer,
      questionType,
    })
  }

  public getAnswer(): SurveyAnswer {
    return this._answer
  }

  public getValue<Q extends QuestionType>(questionType: Q): AnswerType<Q> {
    if (questionType !== this._answer.questionType) {
      this.log.error(
        `Question type ${questionType} does not match answer type ${this._answer.questionType}`,
      )
    }
    if (this._answer.value === null) {
      return null as AnswerType<Q>
    }
    return this._answer.value as AnswerType<Q>
  }

  public hasId(id: string | number, questionType: QuestionType): boolean {
    const value = this.getValue(questionType)
    if (value === null) {
      return false
    }
    return this._findNestedValue(id, value) !== null
  }

  public getNestedValueAsNumber(id: string | number, questionType: QuestionType): number | null {
    const value = this.getNestedValue(id, questionType)
    if (value === null) {
      return null
    }
    const numValue = Number(value.value)
    if (Number.isNaN(numValue)) {
      return null
    }
    return numValue
  }

  public isValid(questionType: QuestionType): boolean {
    switch (questionType) {
      case 'multiInput':
      case 'matrix':
        return this._answer.validity?.valid ?? false
      default:
        return true
    }
  }

  public invalidMessages(questionType: QuestionType): string[] {
    return this._answer.validity?.messages ?? ['This question requires an answer']
  }

  public getNestedValue(id: string | number, questionType: QuestionType): AnswerValue | null {
    const value = this.getValue(questionType)
    if (value === null) {
      return null
    }
    return this._findNestedValue(id, value)
  }

  private _findNestedValue(id: string | number, value: GeneralisedAnswerValue): AnswerValue | null {
    if (Array.isArray(value)) {
      return value.find((v) => v.id === id) || null
    } else if (isSingleAnswerValue(value)) {
      return value.id === id ? value : null
    } else {
      return this._findNestedValue(id, value ? value[id] : null)
    }
  }
}
