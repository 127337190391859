import { LoggingService } from './LoggingService'
import { CrashReportingService } from '../crash/CrashReportingService'

export class MockLoggingService extends LoggingService {
  public async initialise(crashReportingService: CrashReportingService): Promise<void> {
    this.debug('MockLoggingService', 'initialise')
  }

  public dispose(): void {
    this.debug('MockLoggingService', 'dispose')
  }

  public debug(...msg: any[]): void {
    console.debug('MockLoggingService', 'debug', msg)
  }

  public log(...msg: any[]): void {
    console.log('MockLoggingService', 'log', msg)
  }

  public info(...msg: any[]): void {
    console.info('MockLoggingService', 'info', msg)
  }

  public warn(...msg: any[]): void {
    console.warn('MockLoggingService', 'warn', msg)
  }

  public error(msg: string, opts?: { error?: Error; context?: any }): void {
    console.error('MockLoggingService', 'error', msg, opts?.error, opts?.context)
  }
}
