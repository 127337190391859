import { FirebaseApp } from 'firebase/app'
import { AnalyticsService } from './AnalyticsService'
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'
import { LoggingService } from '../logging/LoggingService'

export class FirebaseAnalyticsService extends AnalyticsService {
  private analytics: Analytics
  private log: LoggingService

  public constructor(firebaseApp: FirebaseApp, loggingService: LoggingService) {
    super()
    this.analytics = getAnalytics(firebaseApp)
    this.log = loggingService
  }

  public async initialise(): Promise<void> {
    return
  }

  public dispose(): void {
    return
  }

  public logEvent(name: string, params: Object): void {
    this.log.info('FirebaseAnalyticsService', 'logEvent', name, params)
    logEvent(this.analytics, name, params)
  }
}
