import {
  FirestoreSubmissionData,
  ResultData,
  ResultDataMetadata,
} from '@shared-types/database/Interfaces'
import { QuestionType } from '@shared-types/questions/Interfaces'
import { AnswerValue, SurveyAnswer, SurveyAnswers } from '@shared-types/surveys/Interfaces'
import { Timestamp } from 'firebase/firestore'
import {
  ChoiceResult,
  OrderBy,
  QuestionResponse,
  SurveyResponse,
  SurveyResponses,
  SurveyResultColumn,
  AttributeDiff,
  FilterBy,
} from '../database/DatabaseManagerService'
import { isSingleAnswerValue } from '../question/utils'
import { maybeMergeStrings } from '../utils/misc'
import { LoggingService } from '../logging/LoggingService'

export interface CSVRow {
  [key: string]: string
}

export type ResponseFormat = 'excelcsv' | undefined

export function csvDataFromResponses(responses: SurveyResponse[]): CSVRow[] {
  return responses.map((response) => {
    const entries = Object.entries(response).map(([key, questionResponse]) => {
      return [key, questionResponse.value]
    })
    return Object.fromEntries(entries)
  })
}

export function csvDataFromChoiceData(choiceData: ChoiceResult[]): CSVRow[] {
  return choiceData.map((choice) => ({
    user: choice.user,
    questionId: choice.questionId,
    choiceNumber: choice.choiceNumber.toString(),
    choice: choice.choice.toString(),
    ...getAttrDiffCells(choice.attrDiffs),
  }))
}

function getAttrDiffCells(attrDiffs: AttributeDiff[]): CSVRow {
  return Object.fromEntries(
    attrDiffs.map((attrDiff) => {
      return [attrDiff.label, attrDiff.diff.toString()]
    }),
  )
}

export function processSubmission(
  submission: FirestoreSubmissionData,
  documentId: string,
): ResultData {
  return {
    answers: {
      //   ..._.mapValues(submission.answers, (value, key) => exportData(value, key)),
      ...submission.answers,
    },

    metadata: {
      userId: submission.userData.uid,
      email: submission.userData.email,
      urlId: submission.userData.urlId,
      device: submission.userData.device,
      browser: submission.userData.browser,
      language: submission.userData.language,

      completed: submission.surveyCompleted,

      totalTime: submission.timeTakenSeconds
        ? submission.timeTakenSeconds
        : submission.timeSoFarSeconds,

      submissionStarted: submission.submissionStarted,
      submissionEnded: submission.submissionEnded,
      lastAnswerTime: submission.lastAnswerTime,
    },

    documentId,
  }
}
// resultDataMetadataMap is a Map where the keys are the fieldNames in Confer, and the values are the fieldNames in Firestore.
// the difference arises from the processSubmission function above
export const resultDataMetadataMap: Record<keyof ResultDataMetadata, string> = {
  userId: 'userData.uid',
  email: 'userData.email',
  urlId: 'userData.urlId',
  device: 'userData.device',
  browser: 'userData.browser',
  language: 'userData.language',
  completed: 'surveyCompleted',
  totalTime: 'timeSoFarSeconds',
  submissionStarted: 'submissionStarted',
  submissionEnded: 'submissionEnded',
  lastAnswerTime: 'lastAnswerTime',
}
export function isKeyOfResultDataMetadata(key: string): key is keyof ResultDataMetadata {
  return key in resultDataMetadataMap
}

export function responsesFromResultData(
  results: ResultData[],
  log: LoggingService,
  format?: ResponseFormat,
): SurveyResponses {
  log.info('responsesFromResultData', results)
  const responseData = results.map((result) => responseFromResult(result, format))

  return {
    data: responseData,
  }
}

function responseFromResult(result: ResultData, format: ResponseFormat): SurveyResponse {
  const questionResponses = responsesFromSurveyAnswers(result.answers)
  const metaDataResponses = responsesFromMetadata(result.metadata, format)
  return {
    ...questionResponses,
    ...metaDataResponses,
    documentId: { value: result.documentId },
  }
}

export function resultHeadersFromResultData(
  results: ResultData[],
  responses: SurveyResponses,
): string[] {
  const headers: string[] = []
  const header_map: Record<string, boolean> = {}
  header_map['documentId'] = true
  headers.push('documentId')
  for (const result of results) {
    for (const name in result.metadata) {
      if (!header_map[name]) {
        header_map[name] = true
        headers.push(name)
      }
    }
  }
  const question_headers: string[] = []
  for (const response of responses.data) {
    for (const qid in response) {
      if (!header_map[qid]) {
        header_map[qid] = true
        question_headers.push(qid)
      }
    }
  }
  question_headers.sort((a, b) => (a > b ? 1 : -1))
  return headers.concat(question_headers)
}

function responsesFromSurveyAnswers(answers: SurveyAnswers): SurveyResponse {
  let entries: [string, QuestionResponse][] = []
  for (const qid in answers) {
    const response = responseFromAnswer(answers[qid])
    if (Array.isArray(response)) {
      for (const resp of response) {
        entries.push([`${qid}::${resp.label}`, resp])
      }
    } else {
      entries.push([qid, response])
    }
  }
  return Object.fromEntries(entries)
}

function responsesFromMetadata(
  metadata: ResultDataMetadata,
  format: ResponseFormat,
): SurveyResponse {
  // log.debug('metadata', metadata)
  return {
    submissionStarted: { value: formatDatetime(metadata.submissionStarted, format) },
    submissionEnded: {
      value: metadata.submissionEnded ? formatDatetime(metadata.submissionEnded, format) : '',
    },
    completed: { value: metadata.completed ? 'true' : 'false' },
    lastAnswerTime: {
      value: metadata.lastAnswerTime ? formatDatetime(metadata.lastAnswerTime, format) : '',
    },
    totalTime: { value: metadata.totalTime.toString() },
    userId: { value: metadata.userId },
    email: { value: metadata.email || '' },
    urlId: { value: metadata.urlId ? maybeMergeStrings(metadata.urlId, ' ') : '' },
    device: { value: metadata.device || '' },
    browser: { value: metadata.browser || '' },
    language: { value: metadata.language || '' },
  }
}

function responseFromAnswer(answer: SurveyAnswer): QuestionResponse | QuestionResponse[] {
  if (answer.value === null) {
    return { value: 'No response' }
  } else if (Array.isArray(answer.value)) {
    return responsesFromArrayValue(answer.value, answer.questionType, '')
  } else if (isSingleAnswerValue(answer.value)) {
    return { value: responseValueFromScalarValue(answer.value, answer.questionType) }
  } else {
    return Object.entries(answer.value).flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return responsesFromArrayValue(value, answer.questionType, key)
      }
      return { value: responseValueFromScalarValue(value, answer.questionType), label: key }
    })
  }
}

function responsesFromArrayValue(
  value: AnswerValue[],
  type: QuestionType,
  key: string,
): QuestionResponse[] {
  const prefix = key ? `${key}::` : ''
  switch (type) {
    default:
      return value.map((val) => ({ value: val.value, label: `${prefix}${val.id}` }))
  }
}

// function responseValueFromArrayValue(value: AnswerValue[], type: QuestionType): string {
//   switch (type) {
//     default:
//       return value.map((val) => val.value).join(';')
//   }
// }

function responseValueFromScalarValue(value: AnswerValue, type: QuestionType): string {
  switch (type) {
    default:
      return value.value
  }
}

function formatDatetime(datetime: Timestamp, format: ResponseFormat): string {
  if (format === 'excelcsv') {
    return datetime.toDate().toLocaleString().replace(',', '')
  } else {
    return datetime.toDate().toLocaleString()
  }
}
