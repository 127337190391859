import { FirebaseUserManagerService } from './modules/auth/FirebaseUserManagerService'
import { FirebaseDatabaseManagerService } from './modules/database/FirebaseDatabaseManagerService'
import initializeFirebase from './modules/firebase/firebaseInit'
import { SentryCrashReportingService } from './modules/crash/SentryCrashReportingService'
import { AnalyticsService } from './modules/analytics/AnalyticsService'
import { FirebaseAnalyticsService } from './modules/analytics/FirebaseAnalyticsService'
import { MockAnalyticsService } from './modules/analytics/MockAnalyticsService'
import { RecorderLoggingService } from './modules/logging/RecorderLoggingService'
import { ServiceContextType } from './modules/services/context'
import { MessageMessagerService } from './modules/messages/MessageMessagerService'
import { RealPopupService } from './modules/popups/RealPopupService'

const firebaseApp = initializeFirebase()

export const loggingService = new RecorderLoggingService() // loggingService constructed before passing to other services' constructors

let analyticsService: AnalyticsService
if (import.meta.env.NODE_ENV === 'production') {
  analyticsService = new FirebaseAnalyticsService(firebaseApp, loggingService)
} else {
  analyticsService = new MockAnalyticsService()
}

const userManagerServiceInstance = new FirebaseUserManagerService(firebaseApp, loggingService)

const databaseManagerServiceInstance = new FirebaseDatabaseManagerService(
  firebaseApp,
  loggingService,
)

const crashReportingService = new SentryCrashReportingService(loggingService)

const messagerService = new MessageMessagerService()

const popupService = new RealPopupService()

export const services: ServiceContextType = {
  userService: userManagerServiceInstance,
  databaseService: databaseManagerServiceInstance,
  crashReportingService: crashReportingService,
  analyticsService: analyticsService,
  loggingService: loggingService,
  messagerService: messagerService,
  popupService: popupService,
}
