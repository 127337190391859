import React from 'react'
import { MockUserManagerService } from '../auth/MockUserManagerService'
import { UserManagerService } from '../auth/UserManagerService'
import { DatabaseManagerService } from '../database/DatabaseManagerService'
import { MockDatabaseManagerService } from '../database/MockDatabaseManagerService'
import { CrashReportingService } from '../crash/CrashReportingService'
import { MockCrashReportingService } from '../crash/MockCrashReportingService'
import { AnalyticsService } from '../analytics/AnalyticsService'
import { MockAnalyticsService } from '../analytics/MockAnalyticsService'
import { LoggingService } from '../logging/LoggingService'
import { MockLoggingService } from '../logging/MockLoggingService'
import { MessagerService } from '../messages/MessagerService'
import { MockMessagerService } from '../messages/MockMessagerService'
import { PopupService } from '../popups/PopupService'
import { MockPopupService } from '../popups/MockPopupService'

export interface ServiceContextType {
  userService: UserManagerService
  databaseService: DatabaseManagerService
  crashReportingService: CrashReportingService
  analyticsService: AnalyticsService
  loggingService: LoggingService
  messagerService: MessagerService
  popupService: PopupService
}

export const ServiceContext = React.createContext<ServiceContextType>({
  userService: new MockUserManagerService(),
  databaseService: new MockDatabaseManagerService(),
  crashReportingService: new MockCrashReportingService(),
  analyticsService: new MockAnalyticsService(),
  loggingService: new MockLoggingService(),
  messagerService: new MockMessagerService(),
  popupService: new MockPopupService(),
})
