import { Observable } from 'rxjs'
import { PopupService } from './PopupService'
import { Popup } from '@shared-types/popups/Interfaces'

export class MockPopupService extends PopupService {
  public setPopup(): void {
    throw new Error('Method not implemented.')
  }
  public clearPopup(): void {
    throw new Error('Method not implemented.')
  }
  public observePopup(): Observable<Popup> {
    throw new Error('Method not implemented.')
  }
}
