// import firebase from 'firebase'
import { EnhancedStore } from '@reduxjs/toolkit'
import {
  AggregatedResults,
  FirestoreSubmissionData,
  FirestoreUserData,
  ResultData,
} from '@shared-types/database/Interfaces'
import { Survey, SurveyAnswers, SurveyAnswer, AnswerValue } from '@shared-types/surveys/Interfaces'
import Bowser from 'bowser'
import { FirebaseApp } from 'firebase/app'
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  Timestamp,
  Unsubscribe,
  collection,
  doc,
  endBefore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  deleteField,
  FirestoreDataConverter,
  getDocFromServer,
  Query,
  QueryConstraint,
  where,
  getCountFromServer,
} from 'firebase/firestore'
import { Functions, getFunctions } from 'firebase/functions'
import queryString from 'query-string'
import { AnyAction, Dispatch, Middleware } from 'redux'
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { getUserDeviceType } from '../../components/helperFunctions'
import { User } from '../auth/User'
import {
  ResponseFormat,
  isKeyOfResultDataMetadata,
  processSubmission,
  resultDataMetadataMap,
} from '../data/converters'
import { appConnectFirestoreEmulator, appConnectFunctionsEmulator } from '../firebase/emulators'
import {
  addSurveyOwner,
  archiveSubmissions,
  createSurveyDocs,
  registerCompletion,
  subscribe,
} from '../firebase/functions'
import { ReduxStoreState } from '../redux/store'
import {
  ChoiceResultColumn,
  ChoiceResults,
  ChoiceResult,
  DatabaseManagerService,
  Pagination,
  OrderBy,
  SurveyData,
  AttributeDiff,
  SurveyResultsParams,
  FilterBy,
} from './DatabaseManagerService'
import {
  Interests,
  MergeFields,
  RegisterCompletionOutput,
  SubscribeOutput,
} from '@shared-types/functions/Interfaces'
import { LoggingService } from '../logging/LoggingService'
import { Profile } from '@shared-types/questions/types/ChoiceExperiment'
import { setSubmissionId } from '../redux/surveyReducer'
import { assertNever } from '../utils/misc'

export class FirebaseDatabaseManagerService extends DatabaseManagerService {
  private firestore: Firestore
  private functions: Functions
  // private getResultsFirestore
  private createSurveyDoc
  private addSurveyOwnerFirestore
  private registerCompletionMailchimpFirestore
  private subscribeMailchimpFirestore
  private archiveSubmissionsFirestore
  private surveyOwnersSubjects: { [key: string]: BehaviorSubject<string[]> } = {}
  private surveysSubject: BehaviorSubject<SurveyData[]> | undefined
  private aggResultsSubject: { [key: string]: BehaviorSubject<AggregatedResults> } = {}
  private surveyResponseCountSubject: BehaviorSubject<number> = new BehaviorSubject(0)
  private surveyResponsesSubject: BehaviorSubject<ResultData[]> = new BehaviorSubject<ResultData[]>(
    [],
  )
  private surveyResponseCountSubscription?: Unsubscribe
  private surveyResponsesSubscription?: Unsubscribe
  private surveyResultsParamsSubject: BehaviorSubject<SurveyResultsParams> =
    new BehaviorSubject<SurveyResultsParams>({
      surveyName: undefined,
      pagination: { action: 'start', pageSize: 10 },
      sorter: { column: 'submissionStarted', direction: 'desc' },
      filter: null,
    })
  private surveyResultsParamsSubscription: Subscription | null = null
  private unsubscribes: Unsubscribe[] = []
  private errorSubject: Subject<Error> = new Subject<Error>()
  private startDoc: QueryDocumentSnapshot<FirestoreSubmissionData> | undefined
  private endDoc: QueryDocumentSnapshot<FirestoreSubmissionData> | undefined
  private log: LoggingService

  public constructor(firebaseApp: FirebaseApp, loggingService: LoggingService) {
    super()
    this.firestore = getFirestore(firebaseApp)
    this.functions = getFunctions(firebaseApp)
    this.log = loggingService
    this.log.info('VITE_USE_EMULATORS', import.meta.env.VITE_USE_EMULATORS)
    if (import.meta.env.VITE_USE_EMULATORS) {
      appConnectFirestoreEmulator(this.firestore)
      appConnectFunctionsEmulator(this.functions)
    }

    // this.getResultsFirestore = getResults(this.functions)
    this.createSurveyDoc = createSurveyDocs(this.functions)
    this.addSurveyOwnerFirestore = addSurveyOwner(this.functions)
    this.registerCompletionMailchimpFirestore = registerCompletion(this.functions)
    this.subscribeMailchimpFirestore = subscribe(this.functions)
    this.archiveSubmissionsFirestore = archiveSubmissions(this.functions)
    this.subscribeToSurveyResultsParams()
  }

  public dispose() {
    this.unsubscribes.forEach((unsubscribe) => {
      unsubscribe()
    })
    if (this.surveyResultsParamsSubscription) {
      this.surveyResultsParamsSubscription.unsubscribe()
    }
  }

  private subscribeToSurveyResultsParams() {
    this.log.info('subscribeToSurveyResultsParams')
    this.surveyResultsParamsSubscription = this.surveyResultsParamsSubject.subscribe(
      ({ surveyName, pagination, sorter, filter }) => {
        if (surveyName === undefined) {
          this.surveyResponseCountSubject.next(0)
          this.surveyResponsesSubject.next([])
          return
        }
        this.updateResponseCount(surveyName)
        this.updateResponses(surveyName, pagination, sorter, filter)
      },
    )
  }

  private updateResponseCount(surveyName: string) {
    if (this.surveyResponseCountSubscription) {
      this.surveyResponseCountSubscription()
    }
    const unsubscribe = onSnapshot(
      this.getSurveyDocRef(surveyName),
      (surveyDoc) => {
        const responseCount = surveyDoc.data()?.submissionCount ?? 0
        this.surveyResponseCountSubject.next(responseCount)
      },
      (error) => {
        this.recordError(new Error('Error updating survey response count'), error)
      },
    )
    this.surveyResponseCountSubscription = unsubscribe
  }

  private updateResponses(
    surveyName: string,
    pagination: Pagination,
    sorter: OrderBy,
    filter: FilterBy | null,
  ) {
    // Make sure to unsubscribe (immediately, instead of when this.dispose() is run) from previous survey responses subscription.
    // This is because if the surveyName or pagination changes, updateResponses will be called, but the old subscription initialised with a different surveyName or pagination will still be active.
    if (this.surveyResponsesSubscription) {
      this.surveyResponsesSubscription()
    }
    const firestoreQuery = this.getObserveResponsesQuery(surveyName, pagination, sorter, filter)
    const unsubscribe = onSnapshot(
      firestoreQuery,
      (submissionQuery) => {
        const docs = submissionQuery.docs
        this.startDoc = docs[0]
        this.endDoc = docs[docs.length - 1]
        const resultData = docs.map((submission) =>
          processSubmission(submission.data(), submission.id),
        )
        this.surveyResponsesSubject.next(resultData)
      },
      (error) => {
        this.recordError(new Error('Error updating survey responses'), error)
      },
    )
    this.surveyResponsesSubscription = unsubscribe
  }

  public async subscribeToStore(
    store: EnhancedStore<
      ReduxStoreState,
      AnyAction,
      readonly Middleware<{}, any, Dispatch<AnyAction>>[]
    >,
    surveyName: string,
    user: User,
  ): Promise<void> {
    this.log.info('subscribeToStore')

    const existingSubmissionId = store.getState().survey.submissionId

    const userData = this.getUserData(user)

    let submissionDocRef: DocumentReference<FirestoreSubmissionData>
    let submissionStarted: Timestamp
    let submissionAccessible = false

    if (existingSubmissionId !== null) {
      submissionDocRef = this.getExistingSubmissionDocRef(surveyName, existingSubmissionId)
      try {
        const submissionDoc = await getDocFromServer(submissionDocRef)
        submissionAccessible = submissionDoc.exists()
        if (submissionAccessible) {
          submissionStarted = submissionDoc.data()?.submissionStarted ?? Timestamp.now()
        }
      } catch (e) {
        this.log.error('Error getting submission', { error: e as Error })
        submissionAccessible = false
      }
    }

    if (existingSubmissionId === null || !submissionAccessible) {
      ;[submissionDocRef, submissionStarted] = await this.createInitialSubmission(
        store,
        surveyName,
        user,
      )
    }

    let currentValues: SurveyAnswers

    const sendUpdate = () => {
      const previousValues = currentValues
      let submissionDoc: Partial<FirestoreSubmissionData>
      currentValues = store.getState().survey.userAnswers
      if (store.getState().survey.currentQuestionType === 'endPage') {
        const submissionEnded = Timestamp.now()
        const timeTakenSeconds = submissionEnded.seconds - submissionStarted.seconds
        submissionDoc = {
          submissionStarted,
          submissionEnded,
          answers: currentValues,
          surveyCompleted: true,
          timeTakenSeconds,
          userData,
        }
        this.updateSubmission(submissionDocRef, submissionDoc)
      }
      if (previousValues !== currentValues) {
        const lastAnswerTime = Timestamp.now()
        submissionDoc = {
          submissionStarted,
          lastAnswerTime,
          answers: currentValues,
          timeSoFarSeconds: lastAnswerTime.seconds - submissionStarted.seconds,
          userData,
        }
        this.updateSubmission(submissionDocRef, submissionDoc)
      }
    }
    this.log.info('Subscribing Firestore to Redux store changes')
    this.unsubscribes.push(store.subscribe(sendUpdate))
  }

  private async createInitialSubmission(
    store: EnhancedStore<
      ReduxStoreState,
      AnyAction,
      readonly Middleware<{}, any, Dispatch<AnyAction>>[]
    >,
    surveyName: string,
    user: User,
  ): Promise<[DocumentReference<FirestoreSubmissionData>, Timestamp]> {
    const userData = this.getUserData(user)

    const submissionStarted = Timestamp.now()

    const submissionDocRef = this.getNewSubmissionDocRef(surveyName)
    store.dispatch(setSubmissionId(submissionDocRef.id))
    const initialSubmission = {
      userData,
      answers: {},
      submissionStarted,
      surveyCompleted: false,
      timeSoFarSeconds: 0,
    }
    this.log.debug('Setting initial response data in firebase', submissionDocRef, initialSubmission)
    await this.initialiseSubmission(submissionDocRef, initialSubmission)

    this.log.info('Finished setting initial response data')
    return [submissionDocRef, submissionStarted]
  }

  private getUserData(user: User): FirestoreUserData {
    const qString = queryString.parse(window.location.search)

    let urlId: string
    if (qString.id && typeof qString.id === 'string') {
      urlId = qString.id
    } else if (qString.id && Array.isArray(qString.id)) {
      urlId = qString.id[0] ? qString.id[0] : 'empty'
    } else {
      urlId = 'empty'
    }

    let noAggregation = false
    if (qString.noAgg && typeof qString.noAgg === 'string') {
      noAggregation = qString.noAgg === 'true'
    } else if (qString.noAgg && Array.isArray(qString.noAgg)) {
      for (const noAgg of qString.noAgg) {
        if (noAgg === 'true') {
          noAggregation = true
        }
      }
    }
    const panelId = typeof qString.panelId === 'string' ? qString.panelId : undefined

    const language = typeof qString.lang === 'string' ? qString.lang : 'default'

    const userData: FirestoreUserData = {
      uid: user.id,
      email: null,
      lastActive: user.lastActive,
      created: user.created,
      urlId,
      device: getUserDeviceType(),
      browser: Bowser.getParser(window.navigator.userAgent).getBrowserName(),
      noAggregation,
      language,
    }
    if (panelId) {
      userData.panelId = panelId
    }
    return userData
  }

  public async getAggregatedResults(surveyName: string): Promise<AggregatedResults> {
    this.log.info('getAggregatedResults', surveyName)
    const surveyDocRef = this.getSurveyDocRef(surveyName)
    const surveyDoc = await getDoc(surveyDocRef)
    this.log.debug('surveyDoc', surveyDoc.data())
    return surveyDoc.data()?.aggResults as AggregatedResults
  }

  public observeAggregatedResults(surveyName: string): Observable<AggregatedResults> {
    if (!this.aggResultsSubject[surveyName]) {
      this.aggResultsSubject[surveyName] = new BehaviorSubject<AggregatedResults>({})
      const unsubscribe = onSnapshot(
        this.getSurveyDocRef(surveyName),
        (surveyDoc) => {
          const aggResults = surveyDoc.data()?.aggResults as AggregatedResults
          this.aggResultsSubject[surveyName].next(aggResults)
        },
        (error) => {
          // this.aggResultsSubject[surveyName].error(error)
          this.recordError(new Error('Error getting aggregate results'), error)
        },
      )
      this.unsubscribes.push(unsubscribe)
    }
    return this.aggResultsSubject[surveyName]
  }

  public async addSurveyOwner(email: string, surveyName: string): Promise<void> {
    await this.addSurveyOwnerFirestore({ email, surveyName })
  }

  public observeSurveyOwners(surveyName: string): Observable<string[]> {
    if (!this.surveyOwnersSubjects[surveyName]) {
      this.surveyOwnersSubjects[surveyName] = new BehaviorSubject<string[]>([])
      const unsubscribe = onSnapshot(
        collection(this.firestore, 'surveys', surveyName, 'surveyOwners'),
        (ownersQuery) => {
          const owners = ownersQuery.docs.map((ownerDoc) => ownerDoc.data().email)
          this.surveyOwnersSubjects[surveyName].next(owners)
        },
        (error) => {
          this.surveyOwnersSubjects[surveyName].error(error)
          this.recordError(new Error('Error getting survey owners'), error as Error)
        },
      )
      this.unsubscribes.push(unsubscribe)
    }
    return this.surveyOwnersSubjects[surveyName]
  }

  public async getSurveyOwners(surveyName: string): Promise<string[]> {
    throw new Error('Not implemented. Need to get value from firestore')
  }

  public async isActive(survey: string): Promise<boolean> {
    this.log.info('isActive', survey)
    try {
      const surveyDoc = await getDoc(this.getSurveyDocRef(survey))
      return surveyDoc.data()?.settings?.active
    } catch (e) {
      this.recordError(new Error('Could not find if survey active'), e as Error)
      return false
    }
  }

  public observeSurveys(user: User): Observable<SurveyData[]> {
    this.log.info('observeSurveys', user)
    if (user.isAdmin) {
      if (!this.surveysSubject) {
        this.surveysSubject = new BehaviorSubject<SurveyData[]>([])
        const unsubscribe = onSnapshot(
          collection(this.firestore, 'surveys'),
          (surveysQuery) => {
            const surveys = surveysQuery.docs.map((surveyDoc) => ({ id: surveyDoc.id }))
            if (this.surveysSubject && surveys.length !== this.surveysSubject.value.length) {
              this.surveysSubject?.next(surveys)
            }
          },
          (error) => {
            this.surveysSubject?.error(error)
          },
        )
        this.unsubscribes.push(unsubscribe)
      }

      return this.surveysSubject
    } else {
      if (!this.surveysSubject) {
        this.surveysSubject = new BehaviorSubject<SurveyData[]>([])
        const unsubscribe = onSnapshot(
          collection(this.firestore, 'users', user.id, 'surveys'),
          (surveysQuery) => {
            const surveys = surveysQuery.docs.map((surveyDoc) => ({ id: surveyDoc.id }))
            if (this.surveysSubject && surveys.length !== this.surveysSubject.value.length) {
              this.surveysSubject?.next(surveys)
            }
          },
        )
        this.unsubscribes.push(unsubscribe)
      }
      return this.surveysSubject
    }
  }

  public async getSurveys(user: User): Promise<SurveyData[]> {
    this.log.info('getSurveys', user)
    if (user.isAdmin) {
      const surveys = await getDocs(collection(this.firestore, 'surveys'))
      let surveyList: SurveyData[] = []
      surveys.forEach((survey) => {
        surveyList.push({ id: survey.id })
      })
      return surveyList
    }
    return []
  }

  public async activateSurvey(surveyName: string) {
    await setDoc(
      this.getSurveyDocRef(surveyName),
      {
        active: true,
        saveResults: true,
        aggregateResults: true,
      },
      { merge: true },
    )
    this.addQuestionsToDb(surveyName)
    // const { settings } = surveyDoc
    // if (!settings) {
    //   const defaultSettings = {
    //     settings: {
    //       active: true,
    //       saveResults: true,
    //       aggregateResults: false,
    //     },
    //   }
    //   db.collection('surveys')
    //     .doc(surveyName)
    //     .set(defaultSettings, { merge: true })
    //   setActive(defaultSettings.settings.active)
    //   setRecording(defaultSettings.settings.saveResults)
    //   setAggregation(defaultSettings.settings.aggregateResults)
    // }
    // if (settings) {
    //   const updateSettings = {
    //     settings: {
    //       active: value, // toggle value
    //     },
    //   }
    //   db.collection('surveys')
    //     .doc(surveyName)
    //     .set(updateSettings, { merge: true })
    //   setActive(updateSettings.settings.active)
    // }
  }
  // private async setRecordAnswers(value) {
  //   const { settings } = surveyDoc
  //   if (settings) {
  //     const updateSettings = {
  //       settings: {
  //         saveResults: value, // toggle value
  //       },
  //     }
  //     db.collection('surveys')
  //       .doc(surveyName)
  //       .set(updateSettings, { merge: true })
  //     setRecording(updateSettings.settings.saveResults)
  //   }
  // }

  private async addQuestionsToDb(surveyName: string) {
    //TODO: do we need to save questions on the survey object
    // const surveyObj = {
    //   questions: [],
    // }
    // // loop through selected survey questions
    // // check if choices is defined
    // // map through choices, create new object with picked object properties
    // // populate questions array with modified array
    // // if choices not defined return empty array
    // console.log('anothertest', Surveys[surveyName])
    // console.log('surveyTest', Surveys[surveyName].questions)
    // Surveys[surveyName].questions.map(questionObj => {
    //   const question = {
    //     id: questionObj.id,
    //     choices: questionObj.choices
    //       ? _.isFunction(questionObj.choices)
    //         ? questionObj.choices(questionObj['choices']).map(item => {
    //             if (item.type) {
    //               return _.pick(item, ['id', 'type', 'text', 'value'])
    //             }
    //             return _.pick(item, ['id', 'text', 'value'])
    //           })
    //         : questionObj.choices.map(item => {
    //             if (item.type) {
    //               return _.pick(item, ['id', 'type', 'text', 'value'])
    //             }
    //             return _.pick(item, ['id', 'text', 'value'])
    //           })
    //       : [],
    //   }
    //   surveyObj.questions.push(question)
    // })
    // db.collection('surveys')
    //   .doc(surveyName)
    //   .set(surveyObj, { merge: true })
  }

  public async refreshSurveys(surveys: Survey[]) {
    let existingSurveys: Record<string, boolean> = {}
    const surveyCollection = await getDocs(collection(this.firestore, 'surveys'))
    surveyCollection.forEach((survey) => {
      existingSurveys[survey.id] = true
    })

    const newSurveys = surveys.filter((survey) => !(survey.settings.surveyName in existingSurveys))

    // for (const survey of surveys) {
    //   if (!(survey.settings.surveyName in existingSurveys)) {
    //     setDoc(this.getSurveyDocRef(survey.settings.surveyName), {}, { merge: true })
    //   }
    // }

    try {
      await Promise.all(
        newSurveys.map((survey) =>
          setDoc(this.getSurveyDocRef(survey.settings.surveyName), {}, { merge: true }),
        ),
      )
    } catch (e) {
      this.recordError(new Error('Error refreshing surveys'), e as Error)
    }

    // this.createSurveyDoc({ list: _.map(Surveys, 'settings.surveyName') })
    //   .then(res => console.log('res', res))
    //   .catch(err => console.log('err', err))
  }

  public observeResponseCount(): Observable<number> {
    this.log.info('observeResponseCounts')
    return this.surveyResponseCountSubject
  }

  public observeResponses(): Observable<ResultData[]> {
    this.log.info('observeResponses')
    return this.surveyResponsesSubject
  }

  private getObserveResponsesQuery(
    surveyName: string,
    pagination: Pagination,
    sorter: OrderBy,
    filter: FilterBy | null,
  ): Query<FirestoreSubmissionData> {
    this.log.info('getObserveResponsesQuery', surveyName, pagination, sorter, filter)
    if (sorter === null && filter === null) {
      sorter = { column: 'submissionStarted', direction: 'desc' }
    }
    const queryConstraints: QueryConstraint[] = []
    queryConstraints.push(limit(pagination.pageSize))
    queryConstraints.push(this.getOrderByQueryConstraint(sorter))
    queryConstraints.push(...this.getFilterByQueryConstraints(filter))
    switch (pagination.action) {
      case 'start':
        break
      case 'current':
        if (this.startDoc) {
          queryConstraints.push(startAt(this.startDoc))
        }
        break
      case 'next':
        if (this.endDoc) {
          queryConstraints.push(startAfter(this.endDoc))
        }
        break
      case 'previous':
        if (this.startDoc) {
          queryConstraints.push(endBefore(this.startDoc), limitToLast(pagination.pageSize))
        } else {
          this.log.debug('Tried to get previous but no startDoc found')
        }
        break
      default:
        assertNever(pagination.action)
    }
    return query(this.getSurveyCollectionRef(surveyName), ...queryConstraints)
  }

  private getOrderByQueryConstraint(sorter: OrderBy): QueryConstraint {
    const orderByColumn = isKeyOfResultDataMetadata(sorter.column)
      ? resultDataMetadataMap[sorter.column]
      : 'submissionStarted'
    return orderBy(orderByColumn, sorter.direction)
  }

  private getFilterByQueryConstraints(filter: FilterBy | null): QueryConstraint[] {
    const queryConstraints: QueryConstraint[] = []
    if (filter && isKeyOfResultDataMetadata(filter.column)) {
      const filterByColumn = resultDataMetadataMap[filter.column]
      switch (filterByColumn) {
        case 'submissionStarted':
        case 'submissionEnded':
        case 'lastAnswerTime':
          if (typeof filter.value !== 'string') break
          if (filter.operator === '==') {
            try {
              const startOfDay = new Date(filter.value)
              const endOfDay = new Date(startOfDay.getTime() + 24 * 60 * 60 * 1000)
              queryConstraints.push(where(filterByColumn, '>=', startOfDay))
              queryConstraints.push(where(filterByColumn, '<', endOfDay))
            } catch (e) {
              this.log.error('Error parsing date', { error: e as Error })
            }
          } else {
            try {
              const date = new Date(filter.value)
              queryConstraints.push(where(filterByColumn, filter.operator, date))
            } catch (e) {
              this.log.error('Error parsing date', { error: e as Error })
            }
          }
          break
        default:
          queryConstraints.push(where(filterByColumn, filter.operator, filter.value))
      }
    }
    return queryConstraints
  }

  public async getResponses(
    surveyName: string,
    pagination?: Pagination,
    format?: ResponseFormat,
  ): Promise<ResultData[]> {
    let results
    if (pagination) {
      results = await this.getResultData(surveyName, pagination)
    } else {
      results = await this.getAllResultData(surveyName)
    }
    return results
  }

  public async getResponseCountAggregate(
    surveyName: string,
    sorter: OrderBy,
    filter: FilterBy | null,
  ): Promise<number> {
    this.log.info('getResponseCountAggregate')
    const queryConstraints: QueryConstraint[] = []
    queryConstraints.push(this.getOrderByQueryConstraint(sorter))
    queryConstraints.push(...this.getFilterByQueryConstraints(filter))
    const surveyCollectionRef = query(this.getSurveyCollectionRef(surveyName), ...queryConstraints)
    const snapshot = await getCountFromServer(surveyCollectionRef)
    const responseCount = snapshot.data().count
    return responseCount
  }

  public setCurrentSurvey(surveyName: string): void {
    this.log.info('setCurrentSurvey', surveyName)
    this.startDoc = undefined
    this.endDoc = undefined
    this.surveyResultsParamsSubject.next({
      surveyName,
      pagination: { action: 'start', pageSize: 10 },
      sorter: { column: 'submissionStarted', direction: 'desc' },
      filter: null,
    })
  }

  public setPagination(pagination: Pagination): void {
    this.log.info('setPagination', pagination)
    this.surveyResultsParamsSubject.next({ ...this.surveyResultsParamsSubject.value, pagination })
  }

  public setOrderBy(sorter: OrderBy): void {
    this.log.info('setSorter', sorter)
    this.startDoc = undefined
    this.endDoc = undefined
    this.surveyResultsParamsSubject.next({
      ...this.surveyResultsParamsSubject.value,
      pagination: { action: 'start', pageSize: 10 },
      sorter,
    })
  }

  public setFilterBy(filter: FilterBy): void {
    this.log.info('setFilterBy', filter)
    this.startDoc = undefined
    this.endDoc = undefined
    this.surveyResultsParamsSubject.next({
      ...this.surveyResultsParamsSubject.value,
      pagination: { action: 'start', pageSize: 10 },
      filter,
    })
  }

  public observeSurveyResultsParams(): Observable<SurveyResultsParams> {
    this.log.info('observeSurveyResultsParams')
    return this.surveyResultsParamsSubject
  }

  private async getAllResultData(surveyName: string): Promise<ResultData[]> {
    const submissionsQuery = query(
      this.getSurveyCollectionRef(surveyName),
      orderBy('submissionStarted', 'desc'),
    )
    try {
      const submissions = await getDocs(submissionsQuery)
      return submissions.docs.map((submission) =>
        processSubmission(submission.data(), submission.id),
      )
    } catch (e) {
      const error = e as Error
      this.log.error(error.message, { error })
      throw e
    }
  }

  private async getResultCount(surveyName: string): Promise<number | undefined> {
    this.log.info('getResultCount', surveyName)
    const survey = await getDoc(this.getSurveyDocRef(surveyName))
    return survey.data()?.submissionCount
  }

  private async getResultData(surveyName: string, pagination: Pagination): Promise<ResultData[]> {
    this.log.info('getResultData', surveyName)
    let submissionsQuery
    switch (pagination.action) {
      case 'start':
        submissionsQuery = query(
          this.getSurveyCollectionRef(surveyName),
          orderBy('submissionStarted', 'desc'),
          limit(pagination.pageSize),
        )
        break
      case 'current':
        if (this.startDoc) {
          submissionsQuery = query(
            this.getSurveyCollectionRef(surveyName),
            orderBy('submissionStarted', 'desc'),
            startAt(this.startDoc),
            limit(pagination.pageSize),
          )
        } else {
          submissionsQuery = query(
            this.getSurveyCollectionRef(surveyName),
            orderBy('submissionStarted', 'desc'),
            limit(pagination.pageSize),
          )
        }
        break
      case 'next':
        if (this.endDoc) {
          submissionsQuery = query(
            this.getSurveyCollectionRef(surveyName),
            orderBy('submissionStarted', 'desc'),
            startAfter(this.endDoc),
            limit(pagination.pageSize),
          )
        } else {
          this.log.debug('Tried to get next, but no endDoc found')
          submissionsQuery = query(
            this.getSurveyCollectionRef(surveyName),
            orderBy('submissionStarted', 'desc'),
            limit(pagination.pageSize),
          )
        }
        break
      case 'previous':
        if (this.startDoc) {
          submissionsQuery = query(
            this.getSurveyCollectionRef(surveyName),
            orderBy('submissionStarted', 'desc'),
            endBefore(this.startDoc),
            limitToLast(pagination.pageSize),
          )
        } else {
          this.log.debug('Tried to get previous but no startDoc found')
          submissionsQuery = query(
            this.getSurveyCollectionRef(surveyName),
            orderBy('submissionStarted', 'desc'),
            limit(pagination.pageSize),
          )
        }
        break
      default:
        assertNever(pagination.action)
    }
    try {
      const submissions = await getDocs(submissionsQuery)
      console.warn('submissions', submissions.docs)
      this.startDoc = submissions.docs[0]
      this.endDoc = submissions.docs[submissions.docs.length - 1]
      if (this.startDoc && this.endDoc) {
        this.log.debug('startDoc', this.startDoc.data().submissionStarted.toDate().toLocaleString())
        this.log.debug('endDoc', this.endDoc.data().submissionStarted.toDate().toLocaleString())
        return submissions.docs.map((submission) =>
          processSubmission(submission.data(), submission.id),
        )
      }
      return []
    } catch (e) {
      const error = e as Error
      this.log.error(error.message, { error })
      throw e
    }
  }

  public async getChoiceData(survey: string): Promise<[ChoiceResults, ChoiceResultColumn[]]> {
    this.log.info('getChoiceData', survey)
    const results = await this.getAllResultData(survey)
    const choiceResults = this.getChoiceResults(results)
    return [choiceResults, this.getChoiceResultColumns(choiceResults)]
  }

  private getChoiceResults(results: ResultData[]): ChoiceResults {
    const choiceResults: ChoiceResults = { data: [] }
    results.forEach((result) => {
      Object.keys(result.answers).forEach((questionId) => {
        const answer = result.answers[questionId]
        if (answer.questionType === 'conjointAnalysis') {
          const userChoiceResults = this.getResponseChoiceResults(result, answer)
          choiceResults.data = choiceResults.data.concat(userChoiceResults)
        }
      })
    })
    return choiceResults
  }

  private getResponseChoiceResults(result: ResultData, answer: SurveyAnswer): ChoiceResult[] {
    const val = answer.value as Record<string, AnswerValue | AnswerValue[]>
    const choices = val.prevChoices as AnswerValue[]
    const choiceResults = choices.map((choice, i) =>
      this.getChoiceResult(choice, result.metadata.userId, i, answer.questionId),
    )
    return choiceResults
  }

  private getChoiceResult(
    choice: AnswerValue,
    userId: string,
    choiceNumber: number,
    questionId: string,
  ): ChoiceResult {
    const choiceResult: ChoiceResult = {
      user: userId,
      questionId: questionId,
      choiceNumber: choiceNumber,
      attrDiffs: this.getAttrDiffs(choice.info?.options as Profile[]),
      choice: choice.info?.selectedOptionIndex as number,
    }
    return choiceResult
  }

  private getAttrDiffs(options: Profile[]): AttributeDiff[] {
    const attrDiffs: AttributeDiff[] = []
    options[0].attributes.forEach((attr, i) => {
      const attrDiff: AttributeDiff = {
        label: `attr:${attr.attributeName ?? attr.attributeId}::level:${
          attr.levelName ?? attr.levelId
        }`,
        diff: 1,
      }
      attrDiffs.push(attrDiff)
    })
    options[1].attributes.forEach((attr, i) => {
      const attrDiff: AttributeDiff = {
        label: `attr:${attr.attributeName ?? attr.attributeId}::level:${
          attr.levelName ?? attr.levelId
        }`,
        diff: -1,
      }
      attrDiffs.push(attrDiff)
    })
    return attrDiffs
  }

  private getChoiceResultColumns(choiceData: ChoiceResults): ChoiceResultColumn[] {
    this.log.info('getChoiceResultColumns', choiceData)
    const columnSet = new Set<string>()
    let choiceResultColumns: ChoiceResultColumn[] = [
      {
        title: 'user',
        dataIndex: 'user',
        key: 'user',
      },
      {
        title: 'questionId',
        dataIndex: 'questionId',
        key: 'questionId',
      },
      {
        title: 'choiceNumber',
        dataIndex: 'choiceNumber',
        key: 'choiceNumber',
      },
      {
        title: 'choice',
        dataIndex: 'choice',
        key: 'choice',
      },
    ]
    let attrColumns: ChoiceResultColumn[] = []
    for (const choice of choiceData.data) {
      choice.attrDiffs.forEach((attrDiff) => {
        const choiceResultColumn: ChoiceResultColumn = {
          title: attrDiff.label,
          dataIndex: attrDiff.label,
          key: attrDiff.label,
        }
        if (!columnSet.has(attrDiff.label)) {
          columnSet.add(attrDiff.label)
          attrColumns.push(choiceResultColumn)
        }
      })
    }
    this.log.debug('choiceResultColumns', choiceResultColumns)
    attrColumns.sort((a, b) => a.title.localeCompare(b.title))
    this.log.debug('attrColumns', attrColumns)
    return choiceResultColumns.concat(attrColumns)
  }

  private recordError(error: Error, actual_error: Error) {
    this.log.error('Database error', { error })
    this.log.error('Database actual error', { error: actual_error })
    this.errorSubject.next(error)
  }

  public observeErrors(): Observable<Error> {
    return this.errorSubject
  }

  private getSurveyDocRef(surveyName: string, ...pathFragments: string[]): DocumentReference {
    return doc(this.firestore, 'surveys', surveyName, ...pathFragments)
  }

  private getSurveyCollectionRef(surveyName: string): CollectionReference<FirestoreSubmissionData> {
    const converter: FirestoreDataConverter<FirestoreSubmissionData> = {
      toFirestore: (data: PartialWithFieldValue<FirestoreSubmissionData>) => data,
      fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) =>
        snapshot.data() as FirestoreSubmissionData,
    }
    // return collection(this.firestore, 'surveys', surveyName, 'submissions')
    return collection(this.firestore, 'surveys', surveyName, 'submissions').withConverter(converter)
  }

  private getNewSubmissionDocRef(surveyName: string): DocumentReference<FirestoreSubmissionData> {
    return doc(this.getSurveyCollectionRef(surveyName))
  }

  private getExistingSubmissionDocRef(
    surveyName: string,
    submissionId: string,
  ): DocumentReference<FirestoreSubmissionData> {
    return doc(this.getSurveyCollectionRef(surveyName), submissionId)
  }

  private async initialiseSubmission(
    docRef: DocumentReference<FirestoreSubmissionData>,
    submission: FirestoreSubmissionData,
  ) {
    await setDoc(docRef, submission)
  }

  private async updateSubmission(
    docRef: DocumentReference<FirestoreSubmissionData>,
    submission: Partial<FirestoreSubmissionData>,
  ) {
    setDoc(docRef, submission, { merge: true })
  }

  public async clearAggregateResults(surveyName: string) {
    this.log.info('clearAggregateResults', surveyName)
    const surveyDocRef = this.getSurveyDocRef(surveyName)
    await updateDoc(surveyDocRef, {
      aggResults: deleteField(),
    })
  }

  public async registerCompletionMailchimp(
    surveyName: string,
    panelId: string,
  ): Promise<RegisterCompletionOutput> {
    this.log.info('registerCompletionMailchimp')
    try {
      const response = await this.registerCompletionMailchimpFirestore({
        surveyName,
        panelId,
      })
      if (!response.data.success) {
        throw new Error('Error registering survey completion with Mailchimp')
      }
      return response.data
    } catch (e) {
      const error = e as Error
      this.log.error(
        `Error registering survey completion with Mailchimp: ${error.name} ${error.message}`,
        { error },
      )
      throw new Error(
        'Error registering survey completion with Mailchimp: ' + error.name + ' ' + error.message,
      )
    }
  }

  public async subscribeMailchimp(
    email_address: string,
    merge_fields?: MergeFields,
    interests?: Interests,
    tags?: string[],
  ): Promise<SubscribeOutput> {
    this.log.info('subscribeMailchimp')
    try {
      const response = await this.subscribeMailchimpFirestore({
        email_address,
        merge_fields,
        interests,
        tags,
      })
      if (response.data.status === 'error') {
        this.log.error('Error subscribing user to Mailchimp')
      }
      return response.data
    } catch (e) {
      const error = e as Error
      this.log.error(`Error subscribing user to Mailchimp ${error.name} ${error.message}`, {
        error,
      })
      return { status: 'error' }
    }
  }

  public async archiveSubmissions(surveyName: string, submissionIds: string[]): Promise<void> {
    this.log.info('archiveSubmissions', surveyName, submissionIds)
    await this.archiveSubmissionsFirestore({ surveyName, submissionIds })
  }
}
