import { CrashReportingService } from './CrashReportingService'

export class MockCrashReportingService extends CrashReportingService {
  public async initialise(): Promise<void> {
    console.log('MockCrashReportingService', 'initialise')
  }

  public dispose(): void {
    console.log('MockCrashReportingService', 'dispose')
  }

  public recordError(error: Error, opts?: { local_msg?: string; context?: any }): void {
    console.log('MockCrashReportingService', 'recordError', error, opts?.local_msg, opts?.context)
  }
}
