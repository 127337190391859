import { Popup } from '@shared-types/popups/Interfaces'
import { Observable } from 'rxjs'

export abstract class PopupService {
  public abstract setPopup(
    popups: PopupsType,
    type: string,
    showPopup: boolean,
    helpPopupsToShow?: string[],
  ): void
  public abstract clearPopup(): void
  public abstract observePopup(): Observable<Popup>
}

export type PopupsType = Record<string, Popup>
