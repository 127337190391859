// import { Question } from "@shared-types/questions/types/Interfaces";

import { Question } from '@shared-types/questions/Interfaces'
import { AnswerValue, GeneralisedAnswerValue, SurveyAnswer } from '@shared-types/surveys/Interfaces'
import _ from 'lodash'
import { Popup } from '@shared-types/popups/Interfaces'
import { PartialRecord, getEntries } from '@shared-types/Util'

// export function checkQuestionType<QuestionType>(question:Question, typeString:string): question is QuestionType {

// }

export function isSingleAnswerValue(value?: GeneralisedAnswerValue): value is AnswerValue {
  return (
    !_.isArray(value) &&
    value !== null &&
    value !== undefined &&
    (typeof value.id === 'string' || typeof value.id === 'number')
  )
}

export function asSingleAnswerValue(value?: GeneralisedAnswerValue): AnswerValue {
  if (isSingleAnswerValue(value)) {
    return value
  }
  throw new Error('Value is not a single answer value')
}

export function asSingleAnswerValueOrNull(value?: GeneralisedAnswerValue): AnswerValue | null {
  if (value === null || isSingleAnswerValue(value)) {
    return value
  }

  throw new Error('Value is not a single answer value')
}

export function asValueArrayOrNull(value: GeneralisedAnswerValue): AnswerValue[] | null {
  if (_.isArray(value)) {
    return value
  } else if (value === null) {
    return null
  }
  throw new Error('Value is not an array or null')
}

export function asValueArray(value: GeneralisedAnswerValue): AnswerValue[] {
  if (_.isArray(value)) {
    return value
  } else if (value === null) {
    return []
  }
  throw new Error('Value is not an array or null')
}

export function asValueRecord(
  value: GeneralisedAnswerValue,
): Record<string, AnswerValue | AnswerValue[]> {
  if (_.isArray(value)) {
    throw new Error('Value is an array')
  } else if (value === null) {
    return {}
  } else if (isSingleAnswerValue(value)) {
    throw new Error('Value is a single answer value')
  } else {
    return value
  }
}

export function getInitialAnswer(question: Question): SurveyAnswer {
  return {
    questionId: question.id,
    questionType: question.type,
    value: null,
  }
}

export function getAnswerValue(answer: SurveyAnswer, id: string | number): string | undefined {
  const answerValue = answer.value
  if (!answerValue) {
    return undefined
  } else if (_.isArray(answerValue)) {
    const ansVal = answerValue.find((val) => val.id === id)
    return ansVal?.value
  } else if (isSingleAnswerValue(answerValue)) {
    if (answerValue.id === id) {
      return answerValue.value
    }
  } else if (answerValue[id]) {
    const ansVal = answerValue[id]
    if (_.isArray(ansVal)) {
      return ansVal.map((val) => val.value).join(', ')
    }
    return ansVal.value
  }
}

export function getAnswerValueAsNumber(
  answer: SurveyAnswer,
  id: string | number,
): number | undefined {
  const val = getAnswerValue(answer, id)
  if (val === undefined || _.isNaN(Number(val))) {
    return undefined
  }
  return Number(val)
}

export function cssLengthToNumber(length: string): number {
  if (length.includes('px')) {
    return Number(length.replace('px', ''))
  } else if (length.includes('rem')) {
    return Number(length.replace('rem', '')) * 16
  } else if (length.includes('em')) {
    return Number(length.replace('em', '')) * 16
  } else {
    return Number(length)
  }
}

/** helper function to overwrite default popups */
export function overwriteDefaultPopups<T extends Record<string, Popup>>(
  defaultPopups: T,
  overwritePopups?: PartialRecord<T>,
): T {
  const newPopups = defaultPopups
  if (overwritePopups !== undefined) {
    for (const [key, value] of getEntries(defaultPopups)) {
      const overwritePopup = overwritePopups[key] ?? {}
      const newPopup = { ...value, ...overwritePopup }
      newPopups[key] = newPopup
    }
  }
  return newPopups
}
