export type OptionallyAsync<Input, Output> = Output | ((input: Input) => Output | Promise<Output>)

/** Fixed length array type: https://stackoverflow.com/questions/41139763/how-to-declare-a-fixed-length-array-in-typescript  */
type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift'
export type FixedLengthArray<T, L extends number, TObj = [T, ...Array<T>]> = Pick<
  TObj,
  Exclude<keyof TObj, ArrayLengthMutationKeys>
> & {
  readonly length: L
  [I: number]: T
  [Symbol.iterator]: () => IterableIterator<T>
}

export type MissingKeys<T, U extends string> = { [K in Exclude<U, keyof T>]: never }
export type FullType<T, U extends string> = T & MissingKeys<T, U>

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][]
export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>

/** A partial record where the values are also partial */
export type PartialRecord<T> = Partial<Record<keyof T, Partial<T[keyof T]>>>
