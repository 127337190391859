import { MessagerConfig, MessagerService } from './MessagerService'
import { message, Alert } from 'antd'
import Error from 'src/assets/icons/error.svg?react'
import Close from 'src/assets/icons/close.svg?react'
import styled from 'styled-components'

export class MessageMessagerService extends MessagerService {
  private antdMessage
  private index = 0
  constructor() {
    super()
    this.antdMessage = message
    this.antdMessage.config({ prefixCls: 'prefix-survey-message', maxCount: 2, duration: 4 })
  }
  public info(message: string): void {
    this.addMessage(message, 'info')
  }
  public success(message: string): void {
    this.addMessage(message, 'success')
  }
  public warning(message: string): void {
    this.addMessage(message, 'warning')
  }
  public error(message: string): void {
    this.addMessage(message, 'error', <Error fill="var(--error)" />)
  }
  private addMessage(
    message: string,
    type: 'info' | 'success' | 'warning' | 'error',
    icon?: React.ReactNode,
  ): void {
    const key = this.index
    this.antdMessage.open({
      key,
      content: (
        <StyledAlert
          type={type}
          message={message}
          closable={true}
          closeIcon={<Close />}
          onClose={() => this.destroy(key)}
          icon={icon}
          showIcon={true}
        />
      ),
      className: 'survey-message',
    })
    this.index++
  }
  public destroy(id: number): void {
    this.antdMessage.destroy(id)
  }
  public destroyAll(): void {
    this.antdMessage.destroy()
  }
  public config(messagerConfig: MessagerConfig): void {
    this.antdMessage.config({ ...messagerConfig, prefixCls: 'prefix-survey-message' })
  }
  public setContainer(container: HTMLElement): void {
    this.antdMessage.config({ getContainer: () => container })
  }
}

const StyledAlert = styled(Alert)`
  font-family: 'Rethink Sans', sans-serif;
  background-color: #ffffff;
  text-align: start;
  border: 0;

  > .ant-alert-icon {
    margin-right: 11px;
  }

  > .ant-alert-close-icon {
    margin-left: 11px;
  }
`
