import { LoggingService } from './LoggingService'
import { CrashReportingService } from '../crash/CrashReportingService'
import { MockCrashReportingService } from '../crash/MockCrashReportingService'
import log from 'loglevel'
export class RecorderLoggingService extends LoggingService {
  private crashReportingService: CrashReportingService

  public constructor() {
    if (import.meta.env.VITE_ENVIRONMENT === 'production') {
      log.setLevel('warn')
    } else {
      log.setLevel('debug')
    }
    super()
    this.crashReportingService = new MockCrashReportingService()

    this.debug = log.debug.bind(log)
    this.log = log.log.bind(log)
    this.info = log.info.bind(log)
    this.warn = log.warn.bind(log)
  }

  public async initialise(crashReportingService: CrashReportingService): Promise<void> {
    this.debug('RecorderLoggingService initialise')
    this.crashReportingService = crashReportingService
  }

  public dispose(): void {
    this.debug('RecorderLoggingService dispose')
  }

  public debug(...msg: any[]): void {
    // log.debug(...msg)
  }

  public log(...msg: any[]): void {
    // log.log(...msg)
  }

  public info(...msg: any[]): void {
    // log.info(...msg)
  }

  public warn(...msg: any[]): void {
    // log.warn(...msg)
  }

  public error(msg: string, opts?: { error?: Error; context?: any }): void {
    const { error, context } = opts ? opts : { error: undefined, context: undefined }
    log.error(msg, error, context)
    this.crashReportingService.recordError(error ?? new Error(msg), { local_msg: msg, context })
  }
}
